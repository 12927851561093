import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { LocaleService } from '../../../Library/services/locale.service';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: number;
  locale: string;
  apiParams: any;
  footerMenu: MenuItem[];
  legalMenu: MenuItem[];

  searchRoute: string = `/${routesTrans[currentLocale].search}`;
  articlesRoute: string = `/${routesTrans[currentLocale].articles}`;

  constructor(
    private localeService: LocaleService,
    private detailService: DetailService,
    @Inject(LOCALE_ID) public localeCode: string
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.footerMenu = result.additional.menus.footer.items;
      }),
      take(1)
    ).subscribe();
  }

  redirectIfCustomUrl(menuItem: MenuItem): void {
    if (menuItem.type === 'custom-url') {
      window.location.href = menuItem.url;
    }
  }
}
