<div class="w-full h-full group relative bg-white flex justify-between items-center hover:cursor-pointer border
border-gray-100 rounded-16 shadow-2 hover:border-gray-300 focus:ring-0 focus:outline-none focus:border-gray-300">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <p *ngIf="!isTablet" class="ml-4 sm:ml-6 font-bold text-16 sm:text-18 leading-125 sm:leading-122 text-gray-600 whitespace-nowrap
            overflow-hidden">
    {{ desktopTitle }}
  </p>
  <p *ngIf="isTablet"
     class="ml-4 sm:ml-6 font-bold text-16 sm:text-18 leading-125 sm:leading-122 text-gray-600 whitespace-nowrap overflow-hidden">
    {{ mobileTitle }}
  </p>
  <span class="text-primary text-24 sm:ml-4 mx-4 sm:mr-6">
    <fa-icon [icon]="['fal','arrow-right']"></fa-icon>
  </span>
</div>
