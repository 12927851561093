<section class="container" style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden" class="w-full flex justify-start items-center">
    <h2 class="font-bold text-24 leading-125 text-gray-600 sm:text-36 sm:leading-115">{{ contentModule.name }}</h2>
  </header>
  <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a051
        [document]="contentModule.slot_type === 'document'"
        [slotable]="slotable"></app-slot-a051>
    </div>
  </div>
</section>
