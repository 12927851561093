import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';

@Component({
  selector: 'app-slot-a20',
  templateUrl: './slot-a20.component.html',
  styleUrls: ['./slot-a20.component.scss']
})
export class SlotA20Component implements OnInit {

  @Input() slotable: any;


  constructor() {

  }

  ngOnInit(): void {
    if (this.slotable.title) {
      this.slotable.title = Helpers.excerpt(this.slotable.title, 80);
    }
  }

}
