import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-slot-a70',
  templateUrl: './slot-a70.component.html',
  styleUrls: ['./slot-a70.component.scss']
})
export class SlotA70Component implements OnInit {

  @Input() slotable: any;
  @Input() document: boolean;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
