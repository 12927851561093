<div class="w-full h-full group relative border border-gray-100 shadow-3 rounded-16 hover:cursor-pointer hover:border-gray-300
           focus:ring-0 focus:outline-none focus:border-gray-300 flex flex-col justify-center">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div class="min-h-20 sm:min-h-32 p-6">
    <p class="font-bold text-gray-600 text-18 sm:text-16 md:text-18 leading-122">
      {{ slotable.title }}
    </p>
  </div>
  <div class="flex justify-between items-center sm:pt-2 md:pt-4 pb-10 pl-6 pr-5 sm:pr-4">
    <span class="text-14 text-gray-400 leading-115">{{ slotable.publishedAtDate | date: 'dd.MM.yyyy' }}</span>
    <a [routerLink]="['/']" class="text-primary font-bold text-16 leading-115 group-hover:underline
      focus:bg-category-agriculture focus:ring-0 focus:outline-none focus:border-none focus:text-gray-600"
       i18n="@@article_card_more">Više</a>
  </div>
</div>


