import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../@shared/shared.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';

import { EnquiriesService } from '../../Library/contact/services/enquiries.service';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentModuleDirective } from './directives/content-module.directive';
import { ContentModuleComponent } from './components/content-module.component';
import { ContentModuleA010Component } from './components/content-module-a010/content-module-a010.component';
import { ContentModuleA020Component } from './components/content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './components/content-module-a030/content-module-a030.component';
import { ContentModuleA040Component } from './components/content-module-a040/content-module-a040.component';
import { ContentModuleA050Component } from './components/content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './components/content-module-a060/content-module-a060.component';
import { ContentModuleA070Component } from './components/content-module-a070/content-module-a070.component';
import { SlotA10Component } from './components/slot-a10/slot-a10.component';
import { SlotA20Component } from './components/slot-a20/slot-a20.component';
import { SlotA30Component } from './components/slot-a30/slot-a30.component';
import { SlotA40Component } from './components/slot-a40/slot-a40.component';
import { SlotA50Component } from './components/slot-a50/slot-a50.component';
import { SlotA60Component } from './components/slot-a60/slot-a60.component';
import { SlotA70Component } from './components/slot-a70/slot-a70.component';
import { RouterModule } from '@angular/router';
import { ContentModuleA041Component } from './components/content-module-a041/content-module-a041.component';
import { SlotA41Component } from './components/slot-a41/slot-a41.component';
import { ContentModuleA051Component } from './components/content-module-a051/content-module-a051.component';
import { SlotA051Component } from './components/slot-a051/slot-a051.component';
import { ContentModuleA061Component } from './components/content-module-a061/content-module-a061.component';
import { SlotA61Component } from './components/slot-a61/slot-a61.component';
import { ContentModuleA052Component } from './components/content-module-a052/content-module-a052.component';
import { SlotA052Component } from './components/slot-a052/slot-a052.component';
import { ContentModuleA053Component } from './components/content-module-a053/content-module-a053.component';
import { SlotA053Component } from './components/slot-a053/slot-a053.component';
import { ContentModuleA042Component } from './components/content-module-a042/content-module-a042.component';
import { ContentModuleA043Component } from './components/content-module-a043/content-module-a043.component';
import { SlotA042Component } from './components/slot-a042/slot-a042.component';
import { SlotA043Component } from './components/slot-a043/slot-a043.component';
import { ContentModuleA011Component } from './components/content-module-a011/content-module-a011.component';
import { SlotA011Component } from './components/slot-a011/slot-a011.component';
import { ContentModuleA044Component } from './components/content-module-a044/content-module-a044.component';
import { SlotA044Component } from './components/slot-a044/slot-a044.component';
import { ContentModuleA001Component } from './components/content-module-a001/content-module-a001.component';
import { ContentModuleA045Component } from './components/content-module-a045/content-module-a045.component';
import { SlotA045Component } from './components/slot-a045/slot-a045.component';
import { ContentModuleA046Component } from './components/content-module-a046/content-module-a046.component';
import { SlotA046Component } from './components/slot-a046/slot-a046.component';
import { ContentModuleA080Component } from './components/content-module-a080/content-module-a080.component';
import { SlotA80Component } from './components/slot-a80/slot-a80.component';
import { ContentModuleD010Component } from './components/content-module-d010/content-module-d010.component';


@NgModule({
  declarations: [
    ContentModuleDirective,
    ContentModuleComponent,
    ContentModuleA001Component,
    ContentModuleA010Component,
    ContentModuleA011Component,
    ContentModuleA020Component,
    ContentModuleA030Component,
    ContentModuleA040Component,
    ContentModuleA041Component,
    ContentModuleA042Component,
    ContentModuleA043Component,
    ContentModuleA044Component,
    ContentModuleA045Component,
    ContentModuleA046Component,
    ContentModuleA050Component,
    ContentModuleA051Component,
    ContentModuleA052Component,
    ContentModuleA053Component,
    ContentModuleA060Component,
    ContentModuleA061Component,
    ContentModuleA070Component,
    ContentModuleA080Component,
    SlotA10Component,
    SlotA20Component,
    SlotA30Component,
    SlotA40Component,
    SlotA41Component,
    SlotA042Component,
    SlotA043Component,
    SlotA044Component,
    SlotA045Component,
    SlotA046Component,
    SlotA50Component,
    SlotA051Component,
    SlotA052Component,
    SlotA60Component,
    SlotA61Component,
    SlotA70Component,
    SlotA053Component,
    SlotA011Component,
    SlotA80Component,
    ContentModuleD010Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ContentModuleComponent,
  ],
  providers: [
    EnquiriesService,
  ],
})
export class ContentModuleModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faLightbulbOn);
  }
}
