<div class="w-full group hover:cursor-pointer pt-21 xs:pt-24 sm:pt-28 mt-6 sm:mt-8 md:pt-37 relative">
  <a *ngIf="!document" class="absolute inset-0 z-10 focus:outline-none" [appFlLink]="slotable.url ? slotable.url : ''" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div *ngIf="slotable.image"
       class="absolute inset-0 mx-auto max-h-76 w-20/24 rounded-16 border border-transparent group-hover:border-primary overflow-hidden">
    <img class="rounded-16 object-cover object-center"
         width="747"
         height="498"
         srcset="{{ slotable.image?.i_368x245 }} 368w, {{ slotable.image?.i_747x498 }} 747w"
         sizes="(max-width: 768px) 368px, (max-width: 1024px) 747px"
         [src]="slotable.image.i_747x498"
         appImgFallback="/assets/img/default/sme-default-image-i_747x498.png"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
    >
  </div>
  <div *ngIf="!slotable.image"
       class="absolute inset-0 mx-auto max-h-76 w-20/24 rounded-16 overflow-hidden">
    <img src="/assets/img/default/sme-default-image-i_747x498.png"
         width="747"
         height="498"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
         class="rounded-16 object-cover object-center border border-transparent group-hover:border-primary">
  </div>
  <div class="bg-primary w-full min-h-80 rounded-24">
    <div class="pt-28 md:pt-42 px-2/24 ml-2 text-white">
      <h3 class="font-bold mt-3 text-30 sm:text-26 md:text-30 leading-115 min-h-30 md:min-h-28">
        {{ slotable.title }}
      </h3>
      <button class="text-primary bg-white rounded-8 font-bold my-8 px-8 py-4 group-hover:bg-opacity-80"
              i18n="@@find_out_more">
        Saznaj više
      </button>
    </div>
  </div>
</div>
