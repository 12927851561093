<div class="container py-0 sm:py-4 md:py-12 min-h-100 sm:min-h-40 md:min-h-100 relative group overflow-hidden">
  <a *ngIf="!document" class="absolute inset-0 z-30 focus:outline-none" [appFlLink]="slotable.url ? slotable.url : ''" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-30 focus:outline-none" title="{{ slotable.title }}"></a>
  <div class="absolute left-4 right-4 xs:left-1 xs:right-1 top-18 sm:top-0 min-h-40 bottom-0 bg-primary rounded-36 z-10"
       [ngClass]="isEven ? 'sm:left-5/24 sm:right-0' : 'sm:right-5/24 sm:left-0'">
  </div>
  <div class="w-full flex sm:self-center z-20 relative group-hover:cursor-pointer"
       [ngClass]="isEven ? 'flex-col sm:flex-row' : 'flex-col sm:flex-row-reverse'">
    <div class="w-0 sm:w-1/24"></div>
    <div class="w-20/24 mx-auto sm:px-0 sm:w-10/24 rounded-24 overflow-hidden sm:self-center border
      border-transparent group-hover:border-primary">
      <div *ngIf="slotable.image" class="w-full pb-16/24 sm:h-full relative rounded-24 overflow-hidden">
        <img class="absolute inset-0 object-center object-cover w-full h-full"
             src="{{ slotable.image.i_562x375 }}"
             srcset="{{ slotable.image.i_368x245 }} 368w, {{ slotable.image.i_562x375 }} 562w"
             sizes="(max-width: 480px), (min-width: 480px)"
             loading="lazy"
             width="562"
             height="375"
             title="{{ slotable.image.alt }}"
             alt="{{ slotable.image.alt }}">
      </div>
      <div *ngIf="!slotable.image" class="w-full pb-16/24 sm:h-full relative rounded-24 overflow-hidden">
        <img src="/assets/img/default/sme-default-image-i_562x375.png"
             class="w-full h-full absolute inset-0 object-center object-cover"
             title="{{ slotable.title }}"
             alt="{{ slotable.title }}">
      </div>
    </div>
    <div class="w-20/24 mx-auto sm:w-12/24 sm:px-1/24 text-white">
      <div class="w-full flex flex-col justify-center items-start">
        <h3 class="mt-4 text-30 md:text-42 leading-115 font-bold text-start">
          {{ slotable.title }}</h3>
        <button class="mt-8 sm:mt-4 md:mt-8 mb-12 sm:mb-4 md:mb-12 w-auto rounded-8 flex justify-center
        items-center text-primary font-bold text-16 py-5 px-8 bg-white group-hover:bg-opacity-80" i18n="@@find_out_more">
          Saznaj više
        </button>
      </div>
    </div>
  </div>
</div>
