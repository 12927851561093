import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-a10',
  templateUrl: './slot-a10.component.html',
  styleUrls: ['./slot-a10.component.scss']
})
export class SlotA10Component implements OnInit {

  @Input() slotable: any;
  @Input() document: boolean;
  constructor() {
  }

  ngOnInit(): void {
  }
}
