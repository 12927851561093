<section class="relative w-full max-h-full">
  <div class="absolute top-0 left-0 bottom-7 sm:bottom-10 bg-system module-a46-backdrop z-0 rounded-r-36"></div>
  <div class="container mt-8 relative mt-6 z-20">
    <header *ngIf="!contentModule.name_hidden" class="w-18/24 sm:w-12/24 sm:ml-5/24 pt-6 sm:pt-8 z-20">
      <h2 class="text-24 sm:text-36 font-bold text-gray-600 leading-125 sm:leading-115">
        {{ contentModule.name }}
      </h2>
      <p class="mt-2 sm:mt-4 text-16 leading-125 font-raleway text-gray">
        {{ contentModule.description }}
      </p>
    </header>
    <div class="w-full sm:w-14/24 sm:ml-5/24 grid grid-cols-1 gap-3 sm:gap-4 mt-8 sm:mt-6">
      <div *ngFor="let slotable of contentModule.slotables"
           class="w-full"
           tabindex="0">
        <app-slot-a046
          [slotType]="contentModule.slot_type"
          [slotable]="slotable">
        </app-slot-a046>
      </div>
    </div>
  </div>
</section>
