import { Component, OnInit } from '@angular/core';
import { ServerResponseService } from 'src/app/Library/server-response.service';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { SeoService } from '../../../Library/services/seo/seo.service';
import { currentLocale } from 'src/app/Library/routes';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { seoRules404 } from '../../../@modules/routing/seo-rules/seo-rules-404';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

  faArrowRight = faArrowRight;

  constructor(
    private seoService: SeoService,
    private srs: ServerResponseService,
  ) {
    srs.setNotFound();
  }

  ngOnInit(): void {
    this.seoService.refresh(new SeoItem(seoRules404[currentLocale]));
  }

}
