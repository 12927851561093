import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../resource/resource.service';
import { EnquiriesAdapter, Enquiry } from '../models/enquiries.model';

@Injectable()

export class EnquiriesService extends ResourceService<Enquiry> {

  constructor(httpClient: HttpClient, adapter: EnquiriesAdapter) {
    super('/enquiries', httpClient, adapter);
  }

}
