import { Component, HostListener, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../models/page.model';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { LocaleService } from '../../../../Library/services/locale.service';
import { ContentModuleService } from '../../../content-module/services/content-module.service';
import { ModuleGroup } from '../../../content-module/models/content-module.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
})
export class PageDetailComponent extends BaseComponent implements OnInit {
  item: Page;
  cleanDescription: any;
  groupedModules: ModuleGroup[];
  color: string = '#4540df';
  pageAccentColor: string = '';
  firstModuleA010: boolean = false;
  firstBreadcrumb: string = '';
  isMobile: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 768 : true;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 768;
    }
  }

  constructor(
    private route: ActivatedRoute,
    protected seoService: SeoService,
    private sanitizer: DomSanitizer,
    private localeService: LocaleService,
    private contentModuleService: ContentModuleService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId,
  ) {
    super(route, seoService, locale, platformId);
  }


  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 768;
    }

    if (this.route.snapshot.data.item) {
      this.item = this.route.snapshot.data.item;

      if (this.item.path === 'naslovna') {
        this.localeService.setCurrentRouteTranslationsForItem();
      } else {
        this.localeService.setCurrentRouteTranslationsForItem(this.item);
      }

      if (this.item) {
        this.pageAccentColor = this.item.color;
      }

      this.firstBreadcrumb = this.getFirstBreadcrumbTitle();

      this.setAccentColor(this.pageAccentColor);

      this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(``);

      const mergedModules = this.contentModuleService.mergeModules(this.item.modules);
      this.groupedModules = this.contentModuleService.createModuleGroups(mergedModules);
      this.firstModuleA010 = this.groupedModules[0]?.modules[0]?.layout === 'moduleA010' ||
        this.groupedModules[0]?.modules[0]?.layout === 'moduleA011';
    }

  }

  get style(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`--page-accent: ${this.color}`);
  }

  setAccentColor(color): void {
    if (color) {
      this.color = color;
    }
  }

  getFirstBreadcrumbTitle(): string {
    switch (this.locale) {
      case 'en':
        return 'Biznis GOV.ME';
      case 'sr-Latn-ME':
        return 'Biznis GOV.ME';
    }
  }

}

