<div class="relative flex flex-col">
  <div
    class="system-background-width absolute top-0 h-17/24 sm:h-full left-0 module-a11-search-spacing sm:bottom-16 bg-system rounded-b-r-36 z-0">
  </div>
  <div
    class="container mx-auto flex relative flex-col sm:flex-row pt-30 sm:pt-36 sm:pt-56 md:min-h-76 z-20">
    <div class="w-20/24 sm:w-14/24 sm:mr-12 mt-6 sm:mt-4">
      <h1
        class="text-left font-bold text-black text-30 md:text-42 leading-115 sm:leading-115">
        {{ contentModule.name }}
      </h1>
      <h2 class="font-medium text-16 text-gray sm:text-20 leading-140 mt-6 sm:mt-8">
        {{ contentModule.description }}
      </h2>
    </div>
    <div class="w-full sm:w-10/24 rounded-16 sm:rounded-24 mt-8 sm:mt-0 relative mb-8 sm:mb-18">
      <app-slot-a011
        class="flex items-start"
        [document]="contentModule.slot_type === 'document'"
        [slotable]="activeSlotable">
      </app-slot-a011>
    </div>
    <div class="w-18/24 hidden absolute sm:top-[350px] md:top-[440px] sm:flex bg-white border border-gray-100 sm:min-h-28 md:min-h-30
    rounded-16 flex-row justify-center items-center sm:justify-between items-center mt-24 z-20 shadow-3 sm:px-8"
         #searchElement>
      <div class="flex justify-start items-center sm:max-w-[500px]">

        <div
          class="flex justify-center items-center w-12 h-12">
          <app-homepage-spinner></app-homepage-spinner>
        </div>

        <span class="flex flex-col items-start justify-center sm:ml-8">
            <span class="mt-2 sm:text-18 font-medium text-gray pt-2 line-clamp-1">
              Success story:
            </span>
              <span class="text-primary font-bold mt-[6px] sm:text-28 leading-122 line-clamp-1">
              {{ activeSlotable.title }}
            </span>
          </span>

      </div>

      <button
        appFlLink={{activeSlotable.url}}
        class="px-18 sm:min-w-56 h-14 bg-primary text-white text-16 sm:text-12 md:text-16 font-bold
          text-center leading-125 rounded-8 hover:bg-category-agriculture hover:text-gray-600 focus:outline-none
          focus:ring-0 focus:border-3 focus:border-gray-200">
        Learn more
      </button>
    </div>

    <div class="w-full flex sm:hidden flex-col">

      <div class="w-full flex justify-start items-center">

        <div class="w-12 h-12 flex justify-center items-center items-center overflow-visible">
          <app-homepage-spinner></app-homepage-spinner>
        </div>

        <span class="flex flex-col items-start justify-center sm:ml-8 ml-4">
            <span class="sm:text-18 font-medium text-gray line-clamp-1">
              Success story:
            </span>
              <span class="text-primary font-bold mt-[6px] sm:text-28 leading-122 line-clamp-1">
              {{ activeSlotable.title }}
            </span>
        </span>
      </div>

      <button
        appFlLink={{activeSlotable.url}}
        class="h-14 bg-primary text-white text-16 font-bold flex justify-center items-center w-full mt-4
          text-center leading-125 rounded-8 hover:bg-category-agriculture hover:text-gray-600 focus:outline-none
          focus:ring-0 focus:border-3 focus:border-gray-200">
        Learn more
      </button>
    </div>

  </div>
</div>

