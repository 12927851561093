import { Injectable } from '@angular/core';
import { Resource } from '../../resource/resource.model';
import { Resource as ResourceInterface } from '../../../Library/resource/resource';
import { BaseAdapter } from '../../adapter/adapter.model';
import { MediaConfigurationService } from '../../services/media-configuration.service';
import { MediaAuthor, MediaAuthorAdapter } from './media-author.model';

export class Media extends Resource<Request> implements ResourceInterface<Media> {

  public id: number;
  public filename: string;
  public original_filename: string;
  public alt: string;
  public extension: string;
  public caption: string;
  public caption_override: string;
  public object_type: string;
  public created_at: string;
  public createdAt: string;
  public uploaded: boolean;
  public failed: boolean;
  public isBlob: boolean;
  public isSelected: boolean;
  public blob: any;
  public pivot: any;
  public errors: any;
  public creator_id: number;
  public author_id: number;
  public source_id: number;
  public topic_id: number;
  public metadata_id: number;

  public author: MediaAuthor;

  // Media Sizes
  public thumbnail: string;
  public default: string;
  public google: string;
  public facebook: string;
  public twitter: string;
  public i_272x182: string;
  public i_368x245: string;
  public i_562x375: string;
  public i_699x466: string;
  public i_747x498: string;
  public i_1200x630: string;
  public i_1200x675: string;
  public i_1920x1280: string;

  public constructor(data?: {}) {
    super(data);
    this.init();
  }

  init(): void {
  }
}

@Injectable({
  providedIn: 'root',
})
export class MediaAdapter extends BaseAdapter<Media> {

  config = null;

  constructor(
    private mediaConfigurationService: MediaConfigurationService,
    private mediaAuthorAdapter: MediaAuthorAdapter,
  ) {
    super(Media);

    this.config = mediaConfigurationService.getConfig('mediaInit');
  }

  adapt(data: any): Media {
    const item = this.updateCropSizes(super.adapt(data));
    if (item.filename) {
      item.filename = item.filename.substring(0, item.filename.indexOf('?'));
    }

    if (item.pivot) {
      item.caption_override = item.pivot.caption;
    }

    const date = new Date(item.created_at);
    item.createdAt = `${date.getDay()}, ${date.getMonth()} ${date.getFullYear()}`;

    if (item.author) {
      item.author = this.mediaAuthorAdapter.adapt(item.author);
    }

    return item;
  }

  updateCropSizes(item: Media): Media {
    const sizes = this.getSizes(item.object_type);

    sizes.forEach(size => {
      const dotIndex = item.filename.lastIndexOf('.');
      if (dotIndex > -1) {
        const sizeName = '-i_' + size.width + 'x' + size.height;
        item[size.name] = item.filename.substr(0, dotIndex) + sizeName + item.filename.substr(dotIndex);
      }
    });

    return item;
  }

  getSizes(objectType): { name: string, width: number, height: number }[] {
    return this.config.sizes[objectType] ? this.config.sizes[objectType] : [];
  }
}
