import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';

@Component({
  selector: 'app-slot-a042',
  templateUrl: './slot-a042.component.html',
  styleUrls: ['./slot-a042.component.scss']
})
export class SlotA042Component implements OnInit {

  @Input() slotable: any;
  @Input() hasBottomLine: boolean;

  constructor() { }

  ngOnInit(): void {
    this.slotable.description = Helpers.excerpt(this.slotable.description, 80);
    this.slotable.title = Helpers.excerpt(this.slotable.title, 60);
  }

}
