import { Component, Input, OnInit } from '@angular/core';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-slot-a60',
  templateUrl: './slot-a60.component.html',
  styleUrls: ['./slot-a60.component.scss']
})
export class SlotA60Component implements OnInit {

  @Input() slotable: any;
  @Input() image: Media;

  constructor() {
  }

  ngOnInit(): void {
  }

}
