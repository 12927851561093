import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export class Field {

  public id?: number;
  public name?: string;
  public slug?: string;
  public gender?: string;
  public color?: string;
  public telephone?: string;
  public icon?: string;
  public locale?: string;
  public created_at?: string;
  public createdAtDate?: Date;
  public createdAtSchemaDate?: string;
  public updated_at?: string;
  public updatedAtDate?: Date;
  public updatedAtSchemaDate?: string;
  public is_adapted: boolean = false;
  public value: boolean = false;
  public valueFilter: number;

  public constructor(data?: {}) {
    Object.assign(this, data);
  }

}

@Injectable({
  providedIn: 'root',
})
export class FieldAdapter extends BaseAdapter<Field> {

  constructor() {
    super(Field);
  }

  adapt(data: any): Field {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    if (item.created_at) {
      item.createdAtDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toJSDate();
      item.createdAtSchemaDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toFormat('y-LL-dd');
    }

    if (item.updated_at) {
      item.updatedAtDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toJSDate();
      item.updatedAtSchemaDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toFormat('y-LL-dd');
    }

    item.is_adapted = true;

    return item;

  }

}




