import { SeoItem } from '../../../Library/services/seo/seo-item.model';

export const seoRules404 = {
  me: new SeoItem({
    title: 'Stranica nije pronađena',
    description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna. '
  }),
  en: new SeoItem({
    title: 'Stranica nije pronađena',
    description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna. '
  })
};
