import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-slot-a052',
  templateUrl: './slot-a052.component.html',
  styleUrls: ['./slot-a052.component.scss']
})
export class SlotA052Component implements OnInit {

  @Input() slotable: any;
  @Input() image: Media;

  constructor() {
  }

  ngOnInit(): void {
    this.slotable.title = Helpers.excerpt(this.slotable.title, 45);
    this.slotable.description = Helpers.excerpt(this.slotable.description, 170);
  }

}
