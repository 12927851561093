import { Injectable } from '@angular/core';
import { Resource } from '../../resource/resource.model';
import { BaseAdapter } from '../../adapter/adapter.model';

export class Enquiry extends Resource<Request> {
  id: number;
  full_name: string;
  email: string;
  content: string;
  enquiry_form_id: number;
  creator_id: number;
  created_at: string;
  updated_at: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EnquiriesAdapter extends BaseAdapter<Enquiry> {
  constructor() {
    super(Enquiry);
  }
}
