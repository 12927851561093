<div class="w-full group relative mt-4 min-h-48 sm:min-h-64 md:min-h-56 py-1 px-6 sm:px-8 bg-white rounded-16 shadow-1
      border border-gray-100 flex flex-col justify-between items-center hover:cursor-pointer
      hover:border-gray-300 focus:ring-0 focus:outline-none focus:border-gray-300">
  <a [appFlLink]="slotable.url ? slotable.url : ''" class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div class="flex justify-start items-center w-full text-primary">
    <div
      *ngIf="slotable.fields?.length === 1"
      [ngStyle]="{'background-color': slotable.fields[0].color + '1A','color':slotable.fields[0].color ,'opacity':'100%'}"
      class="min-w-30 min-h-8 bg-opacity-10 rounded-8 flex font-bold px-2 flex items-center my-4">
      <fa-icon [icon]="['fal', slotable.fields[0].icon]"></fa-icon>
      <span class="text-12 ml-2">{{ slotable.fields[0].name }}</span>
    </div>
    <div *ngIf="slotable.fields?.length > 1"
         class="min-w-30 min-h-8 bg-status-information bg-opacity-10 rounded-8 flex font-bold px-2
          flex items-center my-4">
      <fa-icon [icon]="['fal', 'grid-2']"></fa-icon>
      <span class="text-12 ml-2">Više oblasti</span>
    </div>
    <div *ngIf="slotable.grant_subtypes.length > 0 "
         class="hidden min-w-28 min-h-8 bg-white border border-gray-100 rounded-8 md:flex font-bold my-4 px-2
          flex items-center text-gray"
         [ngClass]="{'ml-4': slotable.fields?.length > 0}"
    >
      <span class="text-12 ml-2">
        <span *ngFor="let grantSubtype of slotable.grant_subtypes | slice: 0:2; let i = index; ">
          {{ grantSubtype?.name }} <span *ngIf="i < slotable.grant_subtypes.length - 1 && i != 1">,</span>
        </span>
      </span>
    </div>
    <div>
    </div>
  </div>
  <div
    class="flex justify-start items-center w-full min-h-16 sm:min-h-18 md:min-w-80 text-18 sm:text-20 font-bold
            text-gray-600 leading-122">
    <h3>
      {{ slotable.title }}
    </h3>
  </div>
  <div class="flex w-full justify-start items-center pt-6 pb-4">
    <span class="flex items-center" *ngIf="slotable.availability === 'open'">
      <span class="w-4 h-4 rounded-full flex justify-center items-center text-category-agriculture">
         <fa-icon [icon]="['fas', 'clock']"></fa-icon>
      </span>
      <span *ngIf="slotable.submit_deadline">
        <span class="pl-2 text-12 sm:text-14 leading-115 text-gray" i18n="@@search_open_until">Otvoren do:</span>
        <span class="pl-1 font-bold sm:text-12 md:text-14 text-gray-600 leading-122 pr-1">
        {{ slotable.submitDeadlineDate | date: 'dd.MM.yyyy' }} / {{ slotable.submitDeadlineDate | date: 'HH:mm' }}
        h</span>
      </span>
      <span *ngIf="!slotable.submit_deadline"
            class="pl-2 text-12 sm:text-14 leading-115 text-gray"
            i18n="@@grant_open">Otvoren</span>
    </span>

    <span class="flex items-center" *ngIf="slotable.availability !== 'open'">
      <span class="w-4 h-4 rounded-full flex justify-center items-center text-red">
         <fa-icon [icon]="['fas', 'clock']"></fa-icon>
      </span>
        <span class="pl-2 text-12 sm:text-14 leading-115 text-gray"
              i18n="@@grant_closed">Zatvoren</span>
    </span>

  </div>
</div>
