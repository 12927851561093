<div id="header" class="w-full bg-transparent absolute top-0 z-100">
  <div class="container pt-6 text-18 text-gray-600">
    <div class="w-full flex items-center justify-end">
      <div class="hidden sm:flex uppercase text-18 font-bold text-gray-600">
        <a *ngIf="!routeTranslations.en"
           (click)="chooseLanguage('en')"
           class="font-bold hover:cursor-pointer">
          <span [ngClass]="{'text-gray-200': locale == 'me'}">ENG</span>
        </a>
        <a *ngIf="routeTranslations.en"
           [href]="routeTranslations.en"
           class="font-bold hover:cursor-pointer">
          <span [ngClass]="{'text-gray-200': locale == 'me'}">ENG</span>
        </a>
        <span class="ml-1">|</span>
        <a *ngIf="!routeTranslations.me"
           (click)="chooseLanguage('me')"
           class="font-bold hover:cursor-pointer">
          <span class="ml-1" [ngClass]="{'text-gray-200': locale == 'en'}">MNE</span>
        </a>
        <a *ngIf="routeTranslations.me"
           [href]="routeTranslations.me"
           class="font-bold hover:cursor-pointer">
          <span class="ml-1" [ngClass]="{'text-gray-200': locale == 'en'}">MNE</span>
        </a>
      </div>
    </div>
    <div class="flex items-center">
      <a [routerLink]="['/']" class="block">
        <img src="assets/img/logos/logo-sme-desktop.png"
             width="348"
             height="144"
             alt="Portal za podršku malim i srednjim preduzećima"
             title="Logo - Portal za podršku malim i srednjim preduzećima"
             class="w-[174px] h-[72px]">
      </a>
    </div>
    <div class="h-14 flex items-center">
      <div #menu class="flex items-center">
        <a *ngFor="let menuItem of headerMenu, index as i"
           [routerLink]="menuItem.type !== 'placeholder' && menuItem.type !== 'custom-url' ? menuItem.link : null"
           routerLinkActive="text-primary"
           [queryParams]="menuItem.query_param"
           class="block hover:text-primary hover:cursor-pointer">
          <span class="mr-5 md:mr-10 flex items-center relative header-option" (click)="onMenuItemClick(i)">
            <span>{{ menuItem.title }}</span>
            <fa-icon *ngIf="menuItem.items[0] && !showChildren[i]"
                     class="ml-2 text-primary text-12"
                     [icon]="['far', 'chevron-down']"
            ></fa-icon>
            <fa-icon *ngIf="menuItem.items[0] && showChildren[i]"
                     class="ml-2 text-primary text-12"
                     [icon]="['far', 'chevron-up']"
            ></fa-icon>
            <span *ngIf="menuItem.items[0] && showChildren[i]" class="header-dropdown">
              <a *ngFor="let dropdownItem of menuItem.items, let first = first"
                 class="block"
                 [routerLink]="dropdownItem.type !== 'placeholder' && dropdownItem.type !== 'custom-url' ? dropdownItem.link : null"
                 [queryParams]="dropdownItem.query_param">
                <div class="w-full px-6 py-4 flex border-t"
                     (click)="redirectIfCustomUrl(dropdownItem)"
                     [ngClass]="{'border-transparent': first, 'border-gray-100': !first}">
                  <span class="text-16 font-bold">{{ dropdownItem.title }}</span>
                </div>
              </a>
            </span>
          </span>
        </a>
      </div>
      <div *ngIf="locale !== 'en'" class="ml-auto flex items-center">
        <a *ngIf="!user && !localStorageHasToken"
           [appFlRouterLink]="['auth', 'login']"
           class="block ui-btn-secondary flex justify-center items-center h-14 px-8">
          <span i18n="@@header_sign_up">Prijavi se</span>
        </a>
        <a [appFlRouterLink]="['profile']"
           *ngIf="user" class="hover:cursor-pointer">
          <div class="w-10 h-10 bg-mustard rounded-full flex items-center">
            <span *ngIf="user.full_name" class="text-white font-bold text-24 mx-auto">
              {{ user.full_name | slice: 0:1 }}
            </span>
          </div>
        </a>
        <div *ngIf="locale == 'me'" class="ml-4 md:ml-8">
          <a [routerLink]="[ locale === 'me' ? searchRoute : 'articleDetail']"
             [queryParams]="{availability: 'open'}">
            <fa-icon class="text-24" [icon]="['fal', 'magnifying-glass']"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
