<div *ngIf="item" [style]="style"  [ngClass]="firstModuleA010 ? 'pt-0' : 'pt-36 sm:pt-56'">
  <!-- Breadcrumbs -->

  <div class="w-full absolute top-24 sm:top-36" *ngIf="item.slug !== 'pocetna'">
    <div class="w-full sm:px-4 container pt-8">
      <ul class="text-14 text-primary font-bold flex flex-row flex-wrap">
        <li class="flex flex-row mr-4">
          <fa-icon *ngIf="isMobile" class="mr-4 text-primary" [icon]="['fal', 'chevron-left']"></fa-icon>
          <a class="underline hover:opacity-70" [routerLink]="['/']" i18n="@@breadcrumbs_home">Početna</a>
        </li>
        <li class="flex flex-row mr-4" *ngFor="let breadcrumb of item.breadcrumbs">
          <span class="flex" *ngIf="!isMobile && item.breadcrumbs[0].title !== firstBreadcrumb">
            <fa-icon class="mr-4 text-primary" [icon]="['fas', 'chevron-right']"></fa-icon>
            <a class="underline hover:opacity-70" [routerLink]="'/' + breadcrumb.url">
              {{ breadcrumb.title }}
            </a>
          </span>
        </li>
        <li class="flex flex-row mr-4" *ngIf="!isMobile">
          <fa-icon class="mr-4 text-primary" [icon]="['fas', 'chevron-right']"></fa-icon>
          <h1 class="text-gray font-medium">{{ item.title }}</h1>
        </li>
      </ul>
    </div>
  </div>

  <div *ngFor="let group of groupedModules, let first = first; let last = last" class="w-full pt-10 sm:pt-0"
       [ngClass]="{'!pt-0': first && firstModuleA010,
       'mt-10 sm:mt-20' : !first,
       'mb-10 sm:mb-20':last}">
    <div [ngClass]="{ 'container flex flex-col sm:flex-row flex-wrap': !group.hasFixedWidth }">
      <div *ngFor="let module of group.modules" class="flex {{ module.column_width }}"
           [ngClass]="{ 'hidden sm:flex' : module.mobile_hidden, 'sm:hidden' : module.desktop_hidden }">
        <app-content-module class="w-full" [contentModule]="module"></app-content-module>
      </div>
    </div>
  </div>
</div>
