<section class="w-full"
         [ngClass]="{'bg-system':contentModule?.is_highlighted == 1,
                     'bg-white': contentModule?.is_highlighted == 0}">
  <div class="container flex flex-col pb-14 pt-13">
    <header *ngIf="!contentModule.name_hidden"
            class="w-full flex content-start z-20">
      <h2 class="text-24 sm:text-36 font-bold text-black leading-125 sm:leading-115">
        {{ contentModule.name }}
      </h2>
    </header>
    <div class="w-full mt-14 sm:mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      <div *ngFor="let slotable of contentModule.slotables; let i = index"
           class=" mt-4 w-full ">
        <app-slot-a042
          [slotable]="slotable"
          [hasBottomLine]="i < contentModule.slotables.length - numberOSlotsWithoutBorder">
        </app-slot-a042>
      </div>
    </div>
  </div>
</section>
