<section class="max-w-max relative sm:pb-56 md:pb-42 text-gray-300 mx-auto min-h-full pt-16">
  <div class="container flex items-center justify-center py-20 md:py-48">
    <div class="flex flex-col justify-center items-center h-full relative">
      <h1 class="text-56 text-primary sm:text-80 font-bold mb-6 leading-100">404</h1>
      <p class="text-18 sm:text-24 mb-6 text-gray-600 leading-100 text-center" i18n="@@404Copy">
        Žao nam je, sadržaj koji ste tražili nije pronađen.
      </p>
      <p class="text-18 sm:text-24 text-gray-600">
        <span>
          <span i18n="@@404_back">Vratite se na</span>
          <a href="/" class="px-2 hover:underline" i18n="@@404LinkHome">naslovnu stranicu</a>
        </span>
      </p>
    </div>
  </div>
</section>
