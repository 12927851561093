import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class GrantField extends Resource<Request> {
  public id: number;
  public locale: string;
  public name: string;
  public slug: string;
  public icon: string;
  public color: string;
  value: number = 0;

  public constructor(data?: {}) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})
export class GrantFieldAdapter extends BaseAdapter<GrantField> {

  constructor(
    private injector: Injector,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
    super(GrantField);
  }

  adapt(data: any): GrantField {
    return super.adapt(data);
  }

}
