import {Injectable} from '@angular/core';
import {Media, MediaAdapter} from '../../../Library/media/models/media.model';
import {Resource} from '../../../Library/resource/resource.model';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';

export class CustomHtml extends Resource<Request> {
  id: number;
  title: string;
  description: string;
  image_id: number;
  image: Media;
  status: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomHtmlAdapter extends BaseAdapter<CustomHtml> {

  constructor(private mediaAdapter: MediaAdapter) {
    super(CustomHtml);
  }

  adapt(data: any): CustomHtml {
    const item = super.adapt(data);

    if (item.image) {
      item.image = this.mediaAdapter.adapt(item.image);
    }

    return item;
  }
}
