import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';

@Component({
  selector: 'app-slot-a41',
  templateUrl: './slot-a41.component.html',
  styleUrls: ['./slot-a41.component.scss']
})
export class SlotA41Component implements OnInit {
  heroImage: string = '/assets/img/moduleA041_hero.png';

  @Input() slotable: any;
  @Input() document: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.slotable.title = Helpers.excerpt(this.slotable.title, 100);
  }

}
