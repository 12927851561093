import {Component, Input, OnInit} from '@angular/core';
import {ContentModule} from '../../models/content-module.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';

@Component({
  selector: 'app-content-module-a046',
  templateUrl: './content-module-a046.component.html',
  styleUrls: ['./content-module-a046.component.scss']
})
export class ContentModuleA046Component implements OnInit {

  @Input() contentModule: ContentModule;
  articleFilterRoute = `/${routesTrans[currentLocale].articles}`;
  searchRoute = `${routesTrans[currentLocale].search}`;
  filterRoute: string = '';

  constructor() { }

  ngOnInit(): void {
    if (this.contentModule.slot_type === 'article') {
      this.filterRoute = this.articleFilterRoute;
    } else {
      this.filterRoute = this.searchRoute;
    }
  }

}
