import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public showHeaderSearchButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public openHeaderSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is404: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRedirect: BehaviorSubject<any> = new BehaviorSubject(null);
  public isDenied: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private cities = [
    'Andrijevica',
    'Bar',
    'Berane',
    'Bijelo Polje',
    'Budva',
    'Cetinje',
    'Danilovgrad',
    'Gusinje',
    'Herceg Novi',
    'Kolašin',
    'Kotor',
    'Mojkovac',
    'Nikšić',
    'Petnjica',
    'Plav',
    'Pljevlja',
    'Plužine',
    'Podgorica',
    'Rožaje',
    'Šavnik',
    'Tivat',
    'Tuzi',
    'Ulcinj',
    'Žabljak',
  ];

  constructor() {
  }

  getCities(): string [] {
    return this.cities;
  }
}
