import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-a011',
  templateUrl: './slot-a011.component.html',
  styleUrls: ['./slot-a011.component.scss']
})
export class SlotA011Component implements OnInit {

  @Input() slotable: any;
  @Input() document: boolean;
  constructor() {
  }

  ngOnInit(): void {
  }

}
