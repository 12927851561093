import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { filter, take, tap } from 'rxjs/operators';
import { User } from 'src/app/@modules/auth/models/User.model';
import { AuthService } from 'src/app/@modules/auth/services/auth.service';
import { MenuItem } from 'src/app/@modules/menu/models/menu-item.model';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {


  routeTranslations: RouteTranslation;
  isMenuOpen: boolean = false;
  apiParams: any;
  headerMenuChain: string = 'main';
  headerMenu: MenuItem[];
  currentlyOpen: number = -1;
  showChildren: boolean[];
  user: User;
  localStorageHasToken: boolean = true;
  locale: string;
  translationHomepage: SafeUrl;
  chosenLanguage: string;
  timeUntilRedirect: number = 5;

  searchRoute = `${routesTrans[currentLocale].search}`;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private authService: AuthService,
    private localeService: LocaleService,
    private sanitizer: DomSanitizer,
    private detailService: DetailService) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe((routeTranslations: RouteTranslation) => {
      this.routeTranslations = routeTranslations;
    });
    this.chosenLanguage = this.locale;
  }

  isMobileLayout: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 768 : true;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth < 768;
    }
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.localStorageHasToken = !!localStorage.getItem('accessToken');
    }

    this.authService.user$.subscribe(
      user => {
        if (isPlatformBrowser(this.platformId)) {
          this.localStorageHasToken = !!localStorage.getItem('accessToken');
        }
        this.user = user;
      }
    );

    this.apiParams = {
      extended_fields: 'items.items'
    };
    this.loadMenu();
  }

  loadMenu(): void {
    this.detailService.details$.pipe(
      filter(res => res != null),
      tap(() => {
      }),
      take(1)
    ).subscribe((apiData) => {
      this.headerMenu = apiData.additional.menus.main.items;
      this.showChildren = new Array<boolean>(this.headerMenu.length);
      for (let i = 0; i < this.showChildren.length; i++) {
        this.showChildren[i] = false;
      }
    });
  }

  redirectToHome(locale: string): void {

    if (locale === 'me') {
      locale = '';
    }
    this.translationHomepage = this.sanitizer.bypassSecurityTrustUrl(environment.websiteUrl + '/' + locale);

    if (isPlatformServer(this.platformId)) {
      return;
    }

    location.href = environment.websiteUrl + '/' + locale;

    // const subscription = interval(1000)
    //   .subscribe(x => {
    //     this.timeUntilRedirect--;
    //     if (this.timeUntilRedirect === 1) {
    //       location.href = environment.websiteUrl + '/' + locale;
    //     }
    //   });
  }

  chooseLanguage(locale: string): void {
    this.chosenLanguage = locale;
    this.redirectToHome(locale);
  }

  onMenuButtonClick(): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  }

  onMenuItemClick(i: number): void {
    this.redirectIfCustomUrl(this.headerMenu[i]);

    this.showChildren[i] = !this.showChildren[i];
    if (this.headerMenu[i].type !== 'placeholder') {
      this.isMenuOpen = false;
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  }

  onChildItemClick(menuItem: MenuItem): void {
    this.redirectIfCustomUrl(menuItem);
    this.isMenuOpen = false;
  }

  onRedirectClick(): void {
    this.isMenuOpen = false;
  }

  redirectIfCustomUrl(menuItem: MenuItem): void {
    if (menuItem.type === 'custom-url') {
      window.location.href = menuItem.url;
    }
  }

}
