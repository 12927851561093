import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { ContentModuleDirective } from '../directives/content-module.directive';
import { ContentModule } from '../models/content-module.model';
import { ContentModuleBaseComponent } from './content-module-base.component';
import { ContentModuleA010Component } from './content-module-a010/content-module-a010.component';
import { ContentModuleA020Component } from './content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './content-module-a030/content-module-a030.component';
import { ContentModuleA040Component } from './content-module-a040/content-module-a040.component';
import { ContentModuleA050Component } from './content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './content-module-a060/content-module-a060.component';
import { ContentModuleA070Component } from './content-module-a070/content-module-a070.component';
import { ContentModuleA041Component } from './content-module-a041/content-module-a041.component';
import { ContentModuleA051Component } from './content-module-a051/content-module-a051.component';
import { ContentModuleA061Component } from './content-module-a061/content-module-a061.component';
import { ContentModuleA052Component } from './content-module-a052/content-module-a052.component';
import { ContentModuleA053Component } from './content-module-a053/content-module-a053.component';
import { ContentModuleA042Component } from './content-module-a042/content-module-a042.component';
import { ContentModuleA043Component } from './content-module-a043/content-module-a043.component';
import { ContentModuleA011Component } from './content-module-a011/content-module-a011.component';
import { ContentModuleA044Component } from './content-module-a044/content-module-a044.component';
import { ContentModuleA001Component } from './content-module-a001/content-module-a001.component';
import { ContentModuleA045Component } from './content-module-a045/content-module-a045.component';
import { ContentModuleA046Component } from './content-module-a046/content-module-a046.component';
import { ContentModuleA080Component } from './content-module-a080/content-module-a080.component';
import { ContentModuleD010Component } from './content-module-d010/content-module-d010.component';


@Component({
  selector: 'app-content-module',
  template: `
    <ng-template appModuleLayoutHost></ng-template>`
})
export class ContentModuleComponent implements OnInit, OnDestroy {
  @Input() contentModule: ContentModule;
  @ViewChild(ContentModuleDirective, {static: true}) appModuleLayoutHost: ContentModuleDirective;
  interval: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.createComponent();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  createComponent(): void {
    let component;

    switch (this.contentModule.layout) {
      case 'moduleA001':
        component = ContentModuleA001Component;
        break;
      case 'moduleA010':
        component = ContentModuleA010Component;
        break;
      case 'moduleA011':
        component = ContentModuleA011Component;
        break;
      case 'moduleA020':
        component = ContentModuleA020Component;
        break;
      case 'moduleA030':
        component = ContentModuleA030Component;
        break;
      case 'moduleA040':
        component = ContentModuleA040Component;
        break;
      case 'moduleA041':
        component = ContentModuleA041Component;
        break;
      case 'moduleA042':
        component = ContentModuleA042Component;
        break;
      case 'moduleA043':
        component = ContentModuleA043Component;
        break;
      case 'moduleA044':
        component = ContentModuleA044Component;
        break;
      case 'moduleA045':
        component = ContentModuleA045Component;
        break;
      case 'moduleA046':
        component = ContentModuleA046Component;
        break;
      case 'moduleA050':
        component = ContentModuleA050Component;
        break;
      case 'moduleA051':
        component = ContentModuleA051Component;
        break;
      case 'moduleA052':
        component = ContentModuleA052Component;
        break;
      case 'moduleA053':
        component = ContentModuleA053Component;
        break;
      case 'moduleA060':
        component = ContentModuleA060Component;
        break;
      case 'moduleA061':
        component = ContentModuleA061Component;
        break;
      case 'moduleA070':
        component = ContentModuleA070Component;
        break;
      case 'moduleA080':
        component = ContentModuleA080Component;
        break;
      case 'moduleD010':
        component = ContentModuleD010Component;
        break;
      default:
        component = ContentModuleA010Component;
    }


    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const containerRef = this.appModuleLayoutHost.viewContainerRef;

    containerRef.clear();

    const componentRef = containerRef.createComponent<ContentModuleBaseComponent>(componentFactory);

    componentRef.instance.contentModule = this.contentModule;
  }
}
