import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import Helpers from '../../../../Library/helpers';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-slot-a046',
  templateUrl: './slot-a046.component.html',
  styleUrls: ['./slot-a046.component.scss']
})
export class SlotA046Component implements OnInit {
  @Input() slotable: any;
  @Input() slotType: string;
  isTablet: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 1140 : true;
  desktopTitle: string;
  mobileTitle: string;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isTablet = window.innerWidth < 1140 && window.innerWidth > 768;
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    this.desktopTitle = Helpers.excerpt(this.slotable.title, 20);
    this.mobileTitle = Helpers.excerpt(this.slotable.title, 20);
  }

}
