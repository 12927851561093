import { Field, FieldAdapter } from '../../fields/models/field.model';
import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { DateTime } from 'luxon';
import { GrantSubtype, GrantSubtypeAdapter } from '../../grant-subtype/models/grant-subtype.model';

export class User {

  id?: number;
  email?: string;
  full_name?: string;
  gender?: string;
  birthdate?: string;
  telephone?: string;
  receive_newsletter?: number;
  company?: string;
  sector?: string;
  place?: string;
  employees_count?: string;
  address?: string;
  city?: string;
  country?: string;
  activated?: number;
  banned?: number;
  interface_language?: string;
  group?: string;
  accessToken?: string;
  fields?: Field[];
  grant_subtypes?: GrantSubtype[];
  is_adapted?: boolean = false;

  public constructor(data?: {}) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserAdapter extends BaseAdapter<User> {

  constructor(private fieldAdapter: FieldAdapter, private grantSubtypeAdapter: GrantSubtypeAdapter) {
    super(User);
  }

  adapt(data: any): User {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    if (item.fields) {
      item.fields = this.fieldAdapter.adaptArray(item.fields);
    }
    if (item.grant_subtypes) {
      item.grant_subtypes = this.grantSubtypeAdapter.adaptArray(item.grant_subtypes);
    }

    item.is_adapted = true;

    return item;
  }

}



