import { SeoItem } from '../../../Library/services/seo/seo-item.model';

export const seoRulesDefault = {
  me: new SeoItem({
    title: 'Portal za podršku malim i srednjim preduzećima',
    description: 'Pronađite program podrške za vaš biznis'
  }),
  en: new SeoItem({
    title: 'Portal za podršku malim i srednjim preduzećima',
    description: 'Pronađite program podrške za vaš biznis'
  })
};

