import {Injectable} from '@angular/core';
import {Media, MediaAdapter} from '../../../Library/media/models/media.model';
import {Resource} from '../../../Library/resource/resource.model';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';

export class Link extends Resource<Request> {
  id: number;
  title: string;
  description: string;
  url: string;
  original_title: string;
  original_description: string;
  image_id: number;
  original_image_id: number;
  image: Media;
  original_image: Media;
  status: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LinkAdapter extends BaseAdapter<Link> {

  constructor(private mediaAdapter: MediaAdapter) {
    super(Link);
  }

  adapt(data: any): Link {
    const item = super.adapt(data);

    if (item.image) {
      item.image = this.mediaAdapter.adapt(item.image);
    }

    if (item.original_image) {
      item.original_image = this.mediaAdapter.adapt(item.original_image);
    }

    return item;
  }
}
