<div class="w-full h-full group relative sm:min-h-34 pb-10"
     [ngClass]="{'border-b border-gray-700': hasBottomLine}">
  <a [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div class="md:pr-3/24">
    <h3 class="text-left text-20 text-white font-bold group-hover:underline leading-125">
      {{ slotable.title }}
    </h3>
    <p class="mt-2 font-16 text-gray-700 font-normal group-hover:text-white leading-125">
      {{ slotable.description }}
    </p>
  </div>
</div>
