<div class="relative flex flex-col">
  <div
    class="system-background-width absolute top-0 left-0 module-a10-search-spacing sm:bottom-16 bg-system rounded-b-r-36 z-0">
  </div>
  <div class="container flex relative flex-col sm:flex-row pt-30 sm:pt-36 sm:pt-56 md:min-h-76 z-20">
    <div class="w-20/24 sm:w-13/24">
      <h1
        class="text-left font-bold text-black text-30 md:text-42 leading-115 sm:leading-115" i18n>
        Portal za podršku malim i srednjim preduzećima
      </h1>
      <h2 class="font-medium text-16 text-gray sm:text-20 leading-140 mt-6 sm:mt-8" i18n>
        Pronađite program podrške za vaš biznis:
      </h2>
    </div>
    <a class="block sm:hidden w-full mt-6 h-18 rounded-36 relative"
       [routerLink]="[searchRoute]" [queryParams]="{availability: 'open'}">
      <input
        type="text"
        class="rounded-36 h-18 w-full border border-gray-300 text-18 placeholder:text-gray-400 leading-115
        placeholder:text-gray-300 text-gray-600 focus:border-primary focus:ring-0 placeholder:font-medium"
        placeholder="Pretraži programe">
      <button
        class="bg-primary absolute right-3 top-3 rounded-full w-12 h-12 text-20 text-white text-center flex justify-center items-center hover:cursor-pointer">
        <fa-icon [icon]="['fal','search']"></fa-icon>
      </button>
    </a>
    <div class="w-full sm:w-10/24 rounded-16 sm:rounded-24 mt-8 sm:mt-0 relative mb-18">
      <app-slot-a10
        [document]="contentModule.slot_type === 'document'"
        [slotable]="activeSlotable"></app-slot-a10>
    </div>
    <div class="w-18/24 hidden absolute sm:top-[350px] md:top-[440px] sm:flex bg-white border border-gray-100 sm:h-28 md:h-30
    rounded-16 flex-row justify-center items-center mt-24 z-20 shadow-3" #searchElement>
      <div
        class="my-4 border-r sm:h-20 md:h-21 border-gray-100 w-9/24 flex justify-between relative hover:cursor-pointer"
        (click)="displayAreaDropdownChanged()">
        <div class="ml-6 md:ml-8 mt-4 md:mt-6 text-gray">
          <p class="text-14 leading-115" i18n>Moj biznis pripada oblasti:</p>
          <span class="mt-1 text-14 md:text-18 font-bold"
                [ngClass]="chosenField === '' ? 'text-gray-200':'text-black'">
            {{ chosenField === '' ? 'Izaberite oblast' : chosenField }}
          </span>
        </div>
        <span class="text-12 text-primary my-8 md:my-10 mr-6">
          <fa-icon *ngIf="!displayAreaDropdown" [icon]="['fas', 'chevron-down']"></fa-icon>
          <fa-icon *ngIf="displayAreaDropdown" [icon]="['fas', 'chevron-up']"></fa-icon>
        </span>
        <div *ngIf="displayAreaDropdown"
             class="w-full h-auto absolute top-21 bg-white rounded-16 shadow-1 z-30 shadow-2 border
             border-gray-200 max-h-72 overflow-y-auto"
             #areaDropdown
        >
          <div *ngFor="let field of fields" class="flex font-bold sm:h-10 md:h-12 justify-start
          items-center hover:bg-gray-100 text-black" (click)="chooseField(field.name)">
            <span class="ml-8">{{ field.name }}</span>
          </div>
        </div>
      </div>
      <div
        class="my-4 border-r sm:h-20 md:h-21 border-gray-100 w-9/24 relative flex justify-between hover:cursor-pointer"
        (click)="displaySupportDropdownChanged()">
        <div class="ml-6 md:ml-8 mt-4 md:mt-6 text-gray">
          <p class="text-12 md:text-14 leading-115" i18n>Potrebna mi je podrška u vidu:</p>
          <span class="mt-1 text-14 md:text-18 font-bold leading-120"
                [ngClass]="choosenJustifyCost === '' ? 'text-gray-200':'text-black'"
          >{{ choosenJustifyCost === '' ? 'Izaberite vid podrške' : choosenJustifyCost }}</span>
        </div>
        <span class="text-12 text-primary my-8 md:my-10 mr-4 md:mr-6">
          <fa-icon *ngIf="!displaySupportDropdown" [icon]="['fas', 'chevron-down']"></fa-icon>
          <fa-icon *ngIf="displaySupportDropdown" [icon]="['fas', 'chevron-up']"></fa-icon>
        </span>
        <div *ngIf="displaySupportDropdown"
             class="w-full h-auto absolute top-21 bg-white rounded-16 shadow-1 z-30 shadow-2 border
             border-gray-200 max-h-72 overflow-y-auto"
             #supportDropdown
        >
          <div *ngFor="let justifyCost of justified_costs"
               class="w-full flex font-bold sm:h-10 md:h-12 justify-start items-center hover:bg-gray-100 text-black"
               (click)="chooseJustifiedCost(justifyCost.name)">
            <span class="ml-8">{{ justifyCost.name }}</span>
          </div>
        </div>
      </div>
      <div class="my-4 sm:h-20 md:h-21 w-6/24 flex justify-center items-center">
        <button
          (click)="search()"
          class="w-32 sm:w-28 md:w-37 h-14 md:h-18 bg-primary text-white text1-6 sm:text-12 md:text-16 font-bold
          text-center leading-125 rounded-8 hover:bg-category-agriculture hover:text-gray-600 focus:outline-none
          focus:ring-0 focus:border-3 focus:border-gray-200">
          Pretraži
        </button>
      </div>
    </div>
  </div>

  <div class="container text-black mt-1 z-10">
    <h3 class="text-24 md:text-36 font-bold leading-125 md:leading-115" i18n>Pretraži po oblastima:</h3>
    <div *ngIf="loading"
         class="transition-all ease-in w-full sm:w-22/24 flex flex-col sm:flex-row justify-between items-center">
      <a
        *ngFor="let number of [0,1,2,3,4]"
        class="ui-field-wrapper">
        <span class="ui-field-icon-wrapper sm:mt-4 relative bg-opacity-10 bg-gray-600 animate-element">
        </span>
        <div
          class="ml-4 sm:ml-2 md:ml-4 text-16 bg-gray-600 text-transparent bg-opacity-10 sm:text-14 md:text-18 font-bold
          leading-125 sm:leading-122 sm:mt-4 animate-element">
          Placeholder
        </div>
      </a>
      <div
        class="w-full sm:w-2/24 my-2 sm:my-0">
        <a [routerLink]="[searchRoute]"
           class="flex justify-center items-center sm:items-start sm:mb-4
                w-full text-white text-24 sm:mt-4 focus:outline-none focus:ring-0 focus:border-3 focus:border-gray-200
                h-14 sm:h-28 md:h-37 bg-primary border border-gray-100 rounded-16 shadow-1 hover:cursor-pointer
                hover:bg-category-agriculture hover:text-gray-600 focus:outline-none focus:ring-0 focus:border-3
                focus:border-gray-200">
          <fa-icon [icon]="['fal','arrow-right']" class="sm:mt-4"></fa-icon>
        </a>
      </div>
    </div>

    <div *ngIf="!loading" class="w-full sm:w-22/24 flex flex-col sm:flex-row justify-between items-center">
      <a
        [routerLink]="[searchRoute]"
        [queryParams]="{field_ids: field.id }"
        *ngFor="let field of fields | slice:0:5"
        class="ui-field-wrapper">
        <span class="ui-field-icon-wrapper sm:mt-4 relative bg-opacity-10"
              [ngStyle]="{'background-color': field.color + '1A'}"
        >
          <fa-icon [icon]="['fal', field.icon]"
                   [ngStyle]="{'color':field.color ,'opacity':'100%'}">
          </fa-icon>
        </span>
        <div class="ml-4 sm:ml-2 md:ml-4 text-16 sm:text-14 md:text-18 font-bold leading-125 sm:leading-122 sm:mt-4">
          <span>{{ field.name }}</span>
        </div>
      </a>
      <div
        class="w-full sm:w-2/24 my-2 sm:my-0">
        <a [routerLink]="[searchRoute]"
           class="flex justify-center items-center sm:items-start sm:mb-4
                w-full text-white text-24 sm:mt-4 focus:outline-none focus:ring-0 focus:border-3 focus:border-gray-200
                h-14 sm:h-28 md:h-37 bg-primary border border-gray-100 rounded-16 shadow-1 hover:cursor-pointer
                hover:bg-category-agriculture hover:text-gray-600 focus:outline-none focus:ring-0 focus:border-3
                focus:border-gray-200">
          <fa-icon [icon]="['fal','arrow-right']" class="sm:mt-4"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
