import { Component, Input, OnInit } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';

@Component({
  templateUrl: './content-module-a020.component.html',
  styleUrls: ['./content-module-a020.component.scss']
})
export class ContentModuleA020Component implements OnInit, ContentModuleBaseComponent {

  @Input() contentModule: ContentModule;


  filterRoute = `/${routesTrans[currentLocale].search}`;


  constructor() {
  }

  ngOnInit(): void {
  }
}
