<div class="w-full group relative hover:cursor-pointer rounded-16 sm:rounded-24 hover:border-primary">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none"></a>
  <div *ngIf="slotable.image"
       class="w-full h-full rounded-16 sm:rounded-24 border border-transparent group-hover:border-primary">
    <img
      class="w-full sm:h-[350px] md:h-[465px] h-full rounded-16 sm:rounded-24 object-cover object-center fade-in-image"
      srcset="{{ slotable.image?.i_368x245 }} 368w, {{ slotable.image?.i_699x466 }} 699w"
      sizes="(max-width: 768px) 368px, (max-width: 1024px) 699px"
      src="{{ slotable.image?.i_699x466 }}"
      appImgFallback="/assets/img/default/sme-default-image-i_699x466.png"
      alt="{{ slotable.title }}"
      title="{{ slotable.title }}"
      width="699"
      height="466"
      loading="lazy"
    >
  </div>
  <div
    *ngIf="!slotable.image"
    class="w-full h-full rounded-16 sm:rounded-24 border border-transparent hover:border-primary">
    <img
      src="/assets/img/default/sme-default-image-i_699x466.png"
      alt="{{ slotable.title }}"
      title="{{ slotable.title }}"
      width="699"
      height="466"
      loading="lazy"
      class="w-full sm:h-[350px] md:h-[465px] h-full rounded-16 sm:rounded-24 object-cover object-center fade-in-image">
  </div>
</div>

