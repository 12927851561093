<div class="w-full h-full group relative border border-gray-100 shadow-3 rounded-16 hover:cursor-pointer
             hover:border-gray-300 focus:ring-0 focus:outline-none focus:border-gray-300">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div class="w-full min-h-52" *ngIf="slotable.image">
    <img class="rounded-t-16 object-contain"
         srcset="{{ slotable.image?.i_368x245 }} 368w, {{ slotable.image?.i_699x466 }} 699w"
         sizes="(max-width: 768px) 368px, (max-width: 1024px) 699px"
         src="{{ slotable.image?.i_699x466 }}"
         appImgFallback="/assets/img/default/sme-default-image-i_699x466.png"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
         loading="lazy"
         width="699"
         height="466"
    >
  </div>
  <div class="w-full min-h-52" *ngIf="!slotable.image">
    <img src="/assets/img/default/sme-default-image-i_747x498.png"
         width="747"
         height="498"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
         class="rounded-t-16 object-contain">
  </div>
  <div class="mt-4 sm:mt-6 px-6">
    <p class="font-bold text-16 sm:text-18 text-gray-600 leading-122 sm:leading-122">
      {{ slotable.title }}
    </p>
  </div>
  <div class="flex justify-between mt-5 sm:mt-10 pl-6 pr-5 pb-4 sm:pb-8 sm:pr-6">
    <span class="text-14 text-gray-400 leading-115">{{ slotable.publishedAtDate| date: 'dd.MM.yyyy' }}</span>
    <a [routerLink]="['/']" class="text-primary font-bold text-16 leading-115 group-hover:underline
      focus:bg-category-agriculture focus:ring-0 focus:outline-none focus:border-none focus:text-gray-600"
       i18n="@@slotA41_more">
      Više
    </a>
  </div>
</div>

