<div class="container">
  <div class="w-full sm:w-14/24 sm:ml-5/24 text-center">
    <h2 class="text-24 md:text-36 leading-125 font-bold text-gray-600">
      Partneri projekta
    </h2>
    <p class="mt-4 md:mt-6 text-16 leading-125 text-gray">
      Projekat podržan od strane Evropske banke za obnovu i razvoj (EBRD) u saradnji sa Vladom Ujedinjenog Kraljevstva: Unapređenje javno - privatnog dijaloga
    </p>
  </div>
  <div class="w-full flex flex-col sm:flex-row items-center mt-6">
    <div class="w-full sm:w-6/24 flex justify-center">
      <img src="assets/img/logos/logo_scc.jpg" alt="Sekretarijat Savjeta za Konkurentnost" width="272" height="160">
    </div>
    <div class="w-full sm:w-6/24 flex justify-center">
      <img src="assets/img/logos/logo_ebrd.jpg" alt="European Bank for Reconstruction and Development" width="273" height="160">
    </div>
    <div class="w-full sm:w-6/24 flex justify-center">
      <img src="assets/img/logos/logo_britanska_ambasada.jpg" alt="Britanska ambasada Podgorica" width="273" height="160">
    </div>
    <div class="w-full sm:w-6/24 flex justify-center">
      <img src="assets/img/logos/logo_UK_aid.jpg" alt="UK aid" width="273" height="160">
    </div>
  </div>
</div>
