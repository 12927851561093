import { Inject, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { currentLocale, routes as routesTrans, RouteName } from './Library/routes';
import { PageDetailComponent } from './@modules/page/components/page-detail/page-detail.component';
import { Page } from './@modules/page/models/page.model';
import { GlobalService } from './Library/services/global.service';
import { PagesService } from './@modules/page/services/pages.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthGuard } from './@modules/auth/guards/auth.guard';

@Injectable()
export class PageResolver implements Resolve<Page> {
  constructor(
    private globalService: GlobalService,
    private service: PagesService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> | Promise<Page> | any {
    const params = {
      extended_fields: 'modules.slotables,modules.image,image,gallery.media,translations'
    };
    let path = state.url;
    const index = path.indexOf('?');
    if (index > -1) {
      path = path.substring(0, index);
    }
    const token = route.queryParamMap.get('token');
    return this.service.getByPath(path, params, token).pipe(
      tap(res => {
        if ((res as any).redirect) {
          this.globalService.isRedirect.next(res);
        }
      }),
      catchError(error => {
        this.globalService.is404.next(true);
        return of(error);
      }));
  }
}

@Injectable()
export class HomepageResolver implements Resolve<Page> {
  constructor(
    private globalService: GlobalService,
    private service: PagesService,
    @Inject(LOCALE_ID) private locale
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> | Promise<Page> | any {
    const params = {
      extended_fields: 'modules.slotables,modules.image,image,gallery.media,translations'
    };
    return this.service.getByPath(this.locale === 'en' ? 'homepage' : 'naslovna', params).pipe(
      catchError(error => {
        this.globalService.is404.next(true);
        return of(error);
      }));
  }
}

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: PageDetailComponent,
        resolve: {
          item: HomepageResolver
        }
      },
      {
        path: routesTrans[currentLocale].auth,
        loadChildren: () => import('./@modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: routesTrans[currentLocale].profile,
        // canActivate: [AuthGuard],
        loadChildren: () => import('./@modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: routesTrans[currentLocale].search,
        loadChildren: () => import('./@modules/search/search.module').then(m => m.SearchModule)
      },
      {
        path: '',
        loadChildren: () => import('./@modules/article/article.module').then(m => m.ArticleModule)
      },
      {
        path: '',
        loadChildren: () => import('./@modules/grant/grant.module').then(m => m.GrantModule)
      },
      {
        path: '**',
        component: PageDetailComponent,
        resolve: {
          item: PageResolver
        },
        data: {
          routeName: RouteName.CUSTOM_PAGE,
          reuseRoute: false
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
