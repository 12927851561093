import { Component, Input, OnInit } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import Helpers from '../../../../Library/helpers';

@Component({
  selector: 'app-content-module-a001',
  templateUrl: './content-module-a001.component.html',
  styleUrls: ['./content-module-a001.component.scss']
})
export class ContentModuleA001Component implements ContentModuleBaseComponent, OnInit {

  @Input() contentModule: ContentModule;
  slot: any;

  shortDescription: string;

  constructor() {
  }

  ngOnInit(): void {
    this.slot = this.contentModule.slotables[0];

    this.shortDescription = Helpers.excerpt(this.slot.description, 150);
  }

}
