<footer class="w-full pt-18 bg-prussian text-16 text-gray-700" id="footer">
  <div class="container sm:flex">
    <div class="sm:w-6/24 flex-col">
      <a href="/" class="mb-4">
        <img src="assets/img/logos/logo-sme-footer-desktop.png"
             width="348"
             height="144"
             alt="Portal za podršku malim i srednjim preduzećima"
             title="Logo - Portal za podršku malim i srednjim preduzećima"
             class="w-[174px] h-[72px]"
        >
      </a>
      <p class="mb-6 text-14 sm:w-42 pt-2" i18n="@@footer_description">
        Portal Vlade Crne Gore za podršku malim i srednjim preduzećima.
      </p>
      <div class="flex mb-12 sm:mb-0">
        <a href="https://www.facebook.com/Ministarstvo-ekonomskog-razvoja-CG-105045238189828/?ref=py_c"
           target="_blank"
           class="footer-social mr-4">
          <fa-icon [icon]="['fab', 'facebook-f']" class="mx-auto"></fa-icon>
        </a>
        <a class="footer-social mr-4"
           href="https://www.instagram.com/mertcrnegore/"
           target="_blank">
          <fa-icon [icon]="['fab', 'instagram']" class="mx-auto"></fa-icon>
        </a>
        <a class="footer-social mr-4"
           href="https://twitter.com/MERTCrneGore"
           target="_blank">
          <fa-icon [icon]="['fab', 'twitter']" class="mx-auto"></fa-icon>
        </a>
        <a
          class="footer-social"
          href="https://www.youtube.com/channel/UCUe9FhH6muwqs62C7Rzk8-w/videos?view=0"
          target="_blank">
          <fa-icon [icon]="['fab', 'youtube']" class="mx-auto"></fa-icon>
        </a>
      </div>
    </div>
    <div class="sm:w-6/24 flex-col mb-16 sm:mb-0" *ngFor="let item of footerMenu">
      <h3 class="text-white text-20 font-bold mb-5">{{ item.title }}</h3>
      <ul>
        <li class="mb-4 block hover:cursor-pointer" *ngFor="let subitem of item.items"
        (click)="redirectIfCustomUrl(subitem)">
          <a
            class="hover:text-white"
            [routerLink]="subitem.type !== 'placeholder' && subitem.type !== 'custom-url' ? subitem.link : null">
            {{ subitem.title }}
          </a>
          <fa-icon *ngIf="subitem.target_blank" [icon]="['fal', 'up-right-from-square']" class="ml-1 text-white"></fa-icon>
        </li>
      </ul>
    </div>

  </div>
  <div class="bg-white mt-12">
    <div class="container flex flex-col sm:flex-row pt-8 pb-10 items-start justify-between">
      <a href="https://www.gov.me/mek">
        <img
          class="mb-10 sm:mb-0 w-[302px]" src="assets/img/logos/logo-mert-footer.png"
          width="604"
          height="110"
          i18n-alt="@@footer_mer"
          title="Logo - Ministarstvo Ekonomskog Razvoja i Turizma"
          alt="Ministarstvo Ekonomskog Razvoja i Turizma">
      </a>
      <div class="flex flex-1 flex-col sm:flex-row items-center sm:justify-end sm:space-x-16">
        <div class="mb-10 sm:mb-0 flex flex-col justify-center items-center group">
          <a href="https://www.ebrd.com/" class="h-[60px] text-center">
            <img
              src="assets/img/logos/logo-ebrd-footer-retina.png"
              class="w-[260px]"
              width="570"
              height="110"
              i18n-alt="@@footer_ebrd"
              title="Logo - Evropska Banka za Rekonstrukciju i Razvoj"
              alt="Evropska Banka za Rekonstrukciju i Razvoj">
          </a>
          <span class="flex flex-col items-center text-gray group-hover:text-darkblue text-12 font-bold leading-115 mt-4 text-center">
            <span>Project</span>
            <span>implementing partner</span>
          </span>
        </div>
        <div class="mb-10 sm:mb-0 flex flex-col justify-center items-center group">
          <a href="https://european-union.europa.eu/index_en" class="h-[60px] text-center">
            <img
              class="mb-10 sm:mb-0 w-[88px]" src="assets/img/logos/logo-eu-footer-r.png"
              width="176"
              height="110"
              i18n-alt="@@footer_eu"
              title="Logo - Evropska Unija"
              alt="Evropska Unija">
          </a>
          <span class="flex flex-col justify-center text-gray group-hover:text-darkblue text-12 font-bold leading-115 mt-4 text-center">
            <span>Co-funded by</span>
            <span>the European Union</span>
          </span>
        </div>
      </div>
    </div>
    <div class="container flex flex-col sm:flex-row items-center py-4">
      <p class="w-full md:w-14/24 text-12 font-medium text-gray-700 text-left leading-115">
        Ovaj vebsajt je izrađen i održava se uz finansijsku podršku Evropske unije. Sadržaj vebsajta je isključivo odgovornost Ministarstva ekonomskog razvoja i turizma i ne odražava nužno stavove Evropske unije.
      </p>
    </div>
    <div class="container flex flex-col sm:flex-row items-center py-4 border-t border-gray-100">
      <p class="text-14 text-gray mb-4 sm:mb-0 xs:text-center sm:text-left">
        <span i18n="@@footer_captcha">Ovaj sajt štiti reCAPTCHA i primjenjuje Google</span>
        <a i18n="@@footer_captcha_1" class="underline hover:text-cyprus" href="https://policies.google.com/privacy?hl=sr" target="_blank">politiku privatnosti</a>
        <span i18n="@@footer_captcha_3">i</span>
        <a class="underline hover:text-cyprus" href="https://policies.google.com/terms?hl=sr" target="_blank" i18n="@@footer_captcha_2">uslove korišćenja usluge</a>.
      </p>
      <p class="sm:ml-auto text-14 text-gray">
        Copyright © {{ currentYear }}. <span i18n="@@footer_copyright">Ministarstvo ekonomskog razvoja i turizma</span>
      </p>
    </div>
  </div>
</footer>
