import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export interface ArticleContentChunk {
  type: string;
  html?: SafeHtml;
  module_id?: number;
}

export class ArticleContent extends Resource<Request> {
  public id?: number;
  public article_id?: number;
  public data?: string;
  public dataArray?: ArticleContentChunk[];

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ArticleContentAdapter extends BaseAdapter<ArticleContent> {
  constructor(
    private sanitizer: DomSanitizer
  ) {
    super(ArticleContent);
  }

  adapt(data: any): ArticleContent {
    const item = data;

    // Check if data contains modules ([module={{module.id}}][/module]
    const regex = /<p>\[module=(\d+)]\[\/module]<\/p>/gm;
    const dataArray = item.data.split(regex);
    item.dataArray = [];

    dataArray.forEach((chunk) => {
      if (isNaN(+chunk)) {
        item.dataArray.push({
          type: 'html',
          html: this.sanitizer.bypassSecurityTrustHtml(chunk)
        });
      } else {
        item.dataArray.push({
          type: 'module',
          module_id: +chunk
        });
      }
    });

    return new ArticleContent(item);
  }
}
