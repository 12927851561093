import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './@shared/components/footer/footer.component';
import { HeaderComponent } from './@shared/components/header/header.component';
import { HeaderMobileComponent } from './@shared/components/header-mobile/header-mobile.component';
import { HeaderDesktopComponent } from './@shared/components/header-desktop/header-desktop.component';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './@shared/layouts/not-found-layout/not-found.component';
import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule, HomepageResolver, PageResolver } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomReuseStrategy } from './Library/custom-reuse-strategy';
import { FormComponent } from './Library/form/form.component';
import { LocaleInterceptor } from './Library/interceptors/locale.interceptor';
import { environment } from '../environments/environment';
import { BaseComponent } from './Library/compontents/base.component';
import { PagesModule } from './@modules/page/pages.module';
import {
  faLightbulbOn,
  faSeedling,
  faChairOffice,
  faRocketLaunch,
  faPhoneVolume,
  faEnvelope,
  faMapMarkedAlt,
  faVolumeMute,
  faLoveseat,
  faPaw,
  faMugHot,
  faBooks,
  faHandReceiving,
  faHandsHelping,
  faCommentAltDots,
  faEye as falEye,
  faSearch,
  faRocket,
  faLaptopCode,
  faSuitcaseRolling,
  faTools,
  faLeaf,
  faHammer,
  faChevronRight,
  faChevronLeft,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faPrint,
  faArrowDownToBracket,
  faTractor,
  faAngleDown,
  faAngleUp,
  faUpRightFromSquare,
  faEyeSlash,
  faMagnifyingGlass,
  faBars,
  faXmark,
  faCoins,
  faBookOpenCover,
  faGrid2,
  faSquare,
  faArrowRight
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faClock,
  faCheck,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight as fasArrowRight
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronUp,
  faFilter
} from '@fortawesome/pro-regular-svg-icons';

import { filter } from 'rxjs/operators';
import { UUIDInterceptor } from './Library/interceptors/uuid.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './@modules/auth/interceptors/auth.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    FooterComponent,
    AppLayoutComponent,
    NotFoundComponent,
    BaseComponent,
    FormComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'sme-portal'}),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule,
    PagesModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: 'base'
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UUIDInterceptor,
      multi: true
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({enabled: environment.production || environment.staging})
    },
    PageResolver,
    HomepageResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  urlBeforeRedirects: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId,
    router: Router, viewportScroller: ViewportScroller,
    library: FaIconLibrary
  ) {

    router.events.pipe(
      filter(e => e instanceof Scroll)
    ).subscribe((e: Scroll) => {
      if (e.position) {
        // backward navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 1000);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 1000);
      } else {
        // forward navigation

        const urlAfterRedirectsIndex = e.routerEvent.urlAfterRedirects.indexOf('?');
        let urlAfterRedirects = e.routerEvent.urlAfterRedirects;
        if (urlAfterRedirectsIndex > -1) {
          urlAfterRedirects = urlAfterRedirects.substr(0, urlAfterRedirectsIndex);
        }
        if (this.urlBeforeRedirects) {
          const urlIndex = this.urlBeforeRedirects.indexOf('?');
          let url = this.urlBeforeRedirects;
          if (urlIndex > -1) {
            url = url.substr(0, urlIndex);
          }
          if (urlAfterRedirects !== url) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }

      }
      this.urlBeforeRedirects = e.routerEvent.urlAfterRedirects;
    });

    // let token = null;
    // if (isPlatformBrowser(this.platformId)) {
    //   token = localStorage.getItem('token');
    // }

    library.addIcons(
      faLightbulbOn,
      faSeedling,
      faChairOffice,
      faRocketLaunch,
      faPhoneVolume,
      faEnvelope,
      faMapMarkedAlt,
      faVolumeMute,
      faLoveseat,
      faPaw,
      faMugHot,
      faBooks,
      faHandReceiving,
      faHandsHelping,
      faCommentAltDots,
      falEye,
      faSearch,
      faRocket,
      faLaptopCode,
      faSuitcaseRolling,
      faTools,
      faLeaf,
      faHammer,
      faArrowRight,
      fasChevronRight,
      fasChevronLeft,
      faPrint,
      faArrowDownToBracket,
      falChevronUp,
      falChevronDown,
      fasChevronUp,
      fasChevronDown,
      faClock,
      faTractor,
      faCheck,
      faAngleDown,
      faAngleUp,
      faFacebookF,
      faInstagram,
      faLinkedinIn,
      faYoutube,
      faUpRightFromSquare,
      faPlus,
      faArrowRightFromBracket,
      faEyeSlash,
      faChevronRight,
      faChevronDown,
      faChevronLeft,
      faChevronUp,
      faMagnifyingGlass,
      faBars,
      faTwitter,
      faXmark,
      faCoins,
      faBookOpenCover,
      faGrid2,
      faFilter,
      faSquare,
      faMinus,
      faArrowLeft,
      faArrowRight,
      fasArrowRight
    );

  }
}
