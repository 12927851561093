import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';
import { Field } from '../../../fields/models/field.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';
import { GrantSubtype } from 'src/app/@modules/grant-subtype/models/grant-subtype.model';
import { GrantSubtypeService } from '../../../grant-subtype/services/grant-subtype.service';
import { Router } from '@angular/router';
import { DetailService } from '../../../detail/services/detail.service';
import { filter, take, tap } from 'rxjs/operators';
import { GrantJustifiedCost } from '../../../grant-justified-cost/models/grant-justified-cost.model';

@Component({
  selector: 'app-content-module-a010',
  templateUrl: './content-module-a010.component.html',
  styleUrls: ['./content-module-a010.component.scss']
})
export class ContentModuleA010Component implements ContentModuleBaseComponent, OnInit {
  chosenField: string = '';
  choosenJustifyCost: string = '';
  @Input() contentModule: ContentModule;
  activeSlotable: any;
  activeSlotableIndex: number = 0;
  displayAreaDropdown: boolean = false;
  displaySupportDropdown: boolean = false;
  justified_costs: GrantJustifiedCost[];
  @ViewChild('searchElement') searchElement: ElementRef;
  fields: Field [];
  loading: boolean = true;
  searchRoute: string = `/${routesTrans[currentLocale].search}`;
  grantSubtypes: GrantSubtype [];
  filterRoute = `${routesTrans[currentLocale].search}`;

  constructor(
    @Inject(PLATFORM_ID) public platformId,
    private elementRef: ElementRef,
    private detailService: DetailService,
    private grantSubtypeService: GrantSubtypeService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.loading = true;
    this.detailService.details$.pipe(
      filter(res => res != null),
      tap(res => {
        this.fields = res.additional.fields.data;
        this.justified_costs = res.additional.justifiedCosts.data;
      }),
      take(1)
    ).subscribe(res => {
      this.loading = false;
    });


    this.activeSlotable = this.contentModule.slotables[this.activeSlotableIndex];
    this.activeSlotableIndex = (this.activeSlotableIndex + 1) % this.contentModule.slotables.length;
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.activeSlotable = this.contentModule.slotables[this.activeSlotableIndex];
        this.activeSlotableIndex = (this.activeSlotableIndex + 1) % this.contentModule.slotables.length;
      }, 4000);
    }
  }


  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.searchElement.nativeElement.contains(event.target)) {
      this.closeAreaDropdown();
      this.closeSupportDropdown();
    }
  }

  redirectToSearch(): void {
    this.router.navigate([this.filterRoute]);
  }

  displayAreaDropdownChanged(): void {
    this.displayAreaDropdown = !this.displayAreaDropdown;
  }

  closeAreaDropdown(): void {
    this.displayAreaDropdown = false;
  }

  closeSupportDropdown(): void {
    this.displaySupportDropdown = false;
  }

  displaySupportDropdownChanged(): void {
    this.displaySupportDropdown = !this.displaySupportDropdown;
  }

  chooseField(field: string): void {
    this.chosenField = field;
  }

  chooseJustifiedCost(justifiedCostName: string): void {
    this.choosenJustifyCost = justifiedCostName;
  }

  search(): void {
    const selectedField = this.fields.find(
      field => field.name === this.chosenField
    );
    const selectedJustifiedCost = this.justified_costs.find(
      grantSubtype => grantSubtype.name === this.choosenJustifyCost
    );

    const queryParams = {};
    if (selectedField) {
      queryParams['field_ids'] = selectedField.id;
    }
    if (selectedJustifiedCost) {
      queryParams['justified_cost_ids'] = selectedJustifiedCost.id;
    }

    this.router.navigate([this.searchRoute], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

}
