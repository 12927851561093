import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-module-a053',
  templateUrl: './content-module-a053.component.html',
  styleUrls: ['./content-module-a053.component.scss']
})
export class ContentModuleA053Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor() {
  }

  ngOnInit(): void {

  }

}
