<section class="container min-h-100 max-h-full">
  <header *ngIf="!contentModule.name_hidden" class="w-full w-full flex content-start">
    <h2 class="text-24 sm:text-36 font-bold text-gray-600 leading-125 sm:leading-115 mb-3 sm:mb-8">
      {{ contentModule.name }}
    </h2>
  </header>
  <div class="w-full flex flex-col sm:flex-row">
    <div class="sm:w-21/24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div *ngFor="let slotable of contentModule.slotables" class="w-full" tabindex="0">
        <app-slot-a41
          [document]="contentModule.slot_type === 'document'"
          [slotable]="slotable"></app-slot-a41>
      </div>
    </div>
    <div *ngIf="contentModule.slot_type !== 'link'"
      class="w-full sm:w-2/24 mt-3 sm:mt-0 sm:ml-4 flex flex-col justify-end">
      <a [routerLink]="filterRoute" class="bg-primary text-white flex justify-center text-24 sm:text-30 items-center
      sm:items-start rounded-16 sm:pt-4 hover:cursor-pointer hover:bg-category-agriculture h-full
      hover:text-gray-600 focus:outline-none focus:ring-0 focus:border-3 focus:border-gray-200">
        <fa-icon [icon]="['fal','arrow-right']"></fa-icon>
      </a>
    </div>
  </div>
</section>


