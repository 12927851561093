<div class="w-full flex flex-col md:flex-row justify-center items-center">
  <div class="mx-2/24 w-full sm:w-10/24 sm:mx-1/24">
    <div class="w-full min-h-52"
         *ngIf="image">
      <img class="rounded-16 object-contain"
           srcset="{{ image.i_272x182 }} 272w, {{ image.i_562x375 }} 562w"
           sizes="(max-width: 768px) 272px, (min-width: 768px) 562px"
           src="{{ image?.i_562x375 }}"
           appImgFallback="/assets/img/default/sme-default-image-i_562x375.png"
           alt="{{ slotable.title }}"
           title="{{ slotable.title }}"
           loading="lazy"
           width="562"
           height="375"
      >
    </div>
    <div class="w-full min-h-52" *ngIf="!image">
      <img src="/assets/img/default/sme-default-image-i_562x375.png"
           width="562"
           height="375"
           alt="{{ slotable.title }}"
           title="{{ slotable.title }}"
           class="rounded-16 object-contain">
    </div>
  </div>
  <div class="mx-2/24 sm:mx-0 w-full sm:w-10/24 flex flex-col md:mr-2/24">
    <h3 class="mt-8 sm:mt-6 text-26 md:text-36 font-bold text-gray-600 leading-115">{{ slotable.title }}</h3>
    <p class="mt-4 text-16 font-normal text-gray leading-125">
      {{ slotable.description }}</p>
    <a [appFlLink]="slotable.url"
       i18n="@@find_out_more"
       class="py-5 rounded-8 w-37 h-14 mt-6 text-white text-16 font-bold bg-primary text-center flex items-center
       justify-center hover:cursor-pointer hover:bg-category-agriculture hover:text-gray-600 focus:outline-none focus:ring-0
       focus:border-3 focus:border-gray-200">
      Saznaj više
    </a>
  </div>
</div>
