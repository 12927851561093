import { Component, Input, OnInit } from '@angular/core';
import Helpers from 'src/app/Library/helpers';

@Component({
  selector: 'app-slot-a043',
  templateUrl: './slot-a043.component.html',
  styleUrls: ['./slot-a043.component.scss']
})
export class SlotA043Component implements OnInit {

  @Input() slotable: any;
  @Input() hasBottomLine: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.slotable.description = Helpers.excerpt(this.slotable.description, 80);
    this.slotable.title = Helpers.excerpt(this.slotable.title, 60);
  }

}
