import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { Document, DocumentAdapter } from '../../document/models/document.model';

export class Instruction extends Resource<Request> {
  public description: string;
  public id: number;
  public items: string;
  public itemsArray: string[];
  public locale: string;
  public title: string;
  public documents: Document[];

  public constructor(data?: {}) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})

export class InstructionAdapter extends BaseAdapter<Instruction> {

  constructor(
    private injector: Injector,
    private documentAdapter: DocumentAdapter,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
    super(Instruction);
  }

  adapt(data: any): Instruction {

    const item = super.adapt(data);

    if (item.items) {
      item.itemsArray = JSON.parse(item.items);
    }

    if (item.documents) {
      item.documents = this.documentAdapter.adaptArray(item.documents);
    }

    return item;
  }

}
