import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a070',
  templateUrl: './content-module-a070.component.html',
})
export class ContentModuleA070Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor() {
  }

  ngOnInit(): void {
  }
}
