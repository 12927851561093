<span class="outline outline-1 outline-offset-[-1px] outline-gray-100 rounded-full relative
            group-hover:bg-category-agriculture focus:outline-primary overflow-visible after:absolute after:inset-0
           slot-a10-loader flex justify-center items-center">
    <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle fill="none" stroke="#4540df" stroke-width="5" cx="98" cy="98" r="46" stroke-dasharray="360"
              stroke-linecap="round" transform="rotate(-90 50 98)">
        <animate attributeName="stroke-dashoffset" values="360;0" dur="4s" repeatCount="indefinite"/>
      </circle>
    </svg>
    <span class="text-primary w-full h-full flex justify-center items-center rounded-full absolute inset-0">
      <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
    </span>
</span>
