<section class="relative w-full max-h-full border border-transparent"
         style="color: var(--page-accent);">
  <div class="absolute top-0 left-0 bottom-28 sm:bottom-24 bg-system module-a20-backdrop rounded-r-36"></div>
  <div class="container relative">
    <header *ngIf="!contentModule.name_hidden"
            class="w-full flex content-start items-center">
      <h2 class="w-auto text-start ml-2 text-gray-600 font-bold text-24 sm:text-36 mt-4 leading-125 md:leading-115">
        {{ contentModule.name }}
      </h2>
    </header>
    <div class="w-full flex flex-col sm:flex-row">
      <div class="hidden sm:flex sm:w-2/24"></div>
      <div class="w-full sm:mt-8 sm:w-22/24 flex flex-col sm:flex-row flex-wrap"
           [ngClass]="{'pt-20': contentModule.name_hidden}">
        <div *ngFor="let slotable of contentModule.slotables"
             class="w-full sm:w-[280px]  md:w-[445px] mr-4"
             tabindex="0">
          <app-slot-a20 [slotable]="slotable"></app-slot-a20>
        </div>
        <div></div>
        <a [routerLink]="filterRoute"
           class="w-full sm:w-20 text-white items-end text-24 mt-4 bg-primary h-14 sm:h-64 md:h-56
        rounded-16 flex justify-center sm:items-start items-center hover:cursor-pointer hover:bg-category-agriculture
        hover:text-gray-600  focus:outline-none focus:ring-0 focus:border-3 focus:border-gray-200">
          <fa-icon [icon]="['fal', 'arrow-right']" class="sm:mt-4"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</section>
