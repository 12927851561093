import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID, Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';
import { interval, Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { filter, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/@modules/auth/services/auth.service';
import { User } from 'src/app/@modules/auth/models/User.model';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderDesktopComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private menuService: MenuService,
    private sanitizer: DomSanitizer,
    private detailService: DetailService,
    private localeService: LocaleService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeCode,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe((routeTranslations: RouteTranslation) => {
      this.routeTranslations = routeTranslations;
    });
  }

  routeTranslations: RouteTranslation;
  locale: string;
  ngUnsubscribe = new Subject();
  menuVisible: boolean = null;
  showRedirectWrapper: string = null;
  translationHomepage: SafeUrl;
  timeUntilRedirect: number = 5;
  headerMenuChain: string = 'main';
  headerMenu: MenuItem[];
  apiParams: any;
  showChildren: boolean[];
  currentlyOpen: number = -1;
  user: User;
  isMobileLayout: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 768 : true;
  @ViewChild('menu') menu: ElementRef;
  localStorageHasToken: boolean = true;

  searchRoute = `${routesTrans[currentLocale].search}`;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.menu.nativeElement.contains(event.target)) {
      if (this.currentlyOpen !== -1) {
        this.showChildren[this.currentlyOpen] = false;
      }
      this.currentlyOpen = -1;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
      this.isMobileLayout = window.innerWidth < 768;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageHasToken = !!localStorage.getItem('accessToken');
    }

    this.apiParams = {
      extended_fields: 'items.items'
    };
    this.authService.user$.subscribe(
      user => {
        if (isPlatformBrowser(this.platformId)) {
          this.localStorageHasToken = !!localStorage.getItem('accessToken');
        }
        this.user = user;
      }
    );
    this.loadMenu();
  }

  redirectToHome(locale: string): void {

    if (locale === 'me') {
      locale = '';
    }
    this.translationHomepage = this.sanitizer.bypassSecurityTrustUrl(environment.websiteUrl + '/' + locale);

    if (isPlatformServer(this.platformId)) {
      return;
    }

    location.href = environment.websiteUrl + '/' + locale;
  }


  chooseLanguage(locale: string): void {
    this.redirectToHome(locale);
  }

  loadMenu(): void {
    this.detailService.details$.pipe(
      filter(res => res != null),
      take(1)
    ).subscribe((apiData) => {
      this.headerMenu = apiData.additional.menus.main.items;
      this.showChildren = new Array<boolean>(this.headerMenu.length);
      for (let i = 0; i < this.showChildren.length; i++) {
        this.showChildren[i] = false;
      }
    });
  }

  onMenuItemClick(i: number): void {
    this.redirectIfCustomUrl(this.headerMenu[i]);

    if (this.currentlyOpen !== -1 && this.currentlyOpen !== i) {
      this.showChildren[this.currentlyOpen] = false;
    }
    this.showChildren[i] = !this.showChildren[i];
    this.currentlyOpen = i;
  }

  redirectIfCustomUrl(menuItem: MenuItem): void {
    if (menuItem.type === 'custom-url') {
      window.location.href = menuItem.url;
    }
  }

}
