import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class GrantJustifiedCost extends Resource<Request> {
  public id: number;
  public locale: string;
  public name: string;
  public slug: string;
  public value: boolean = false;

  public constructor(data?: {}) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})
export class GrantJustifiedCostAdapter extends BaseAdapter<GrantJustifiedCost> {

  constructor(
    private injector: Injector,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
    super(GrantJustifiedCost);
  }

  adapt(data: any): GrantJustifiedCost {
    return super.adapt(data);
  }

}
