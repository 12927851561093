<div class="container py-0 sm:py-4 md:py-12 min-h-100 sm:min-h-40 md:min-h-100 relative group overflow-hidden">
  <div class="absolute left-4 right-4 xs:left-1 xs:right-1 top-18 sm:top-0 min-h-40 bottom-0 bg-primary rounded-36 z-10
            sm:right-5/24 sm:left-0">
  </div>
  <div class="w-full flex sm:self-center z-20 relative flex-col sm:flex-row-reverse">
    <div class="w-0 sm:w-1/24"></div>
    <div class="w-20/24 mx-auto sm:px-0 sm:w-10/24 rounded-24 overflow-hidden sm:self-center border
      border-transparent group-hover:border-primary">
      <div *ngIf="image"
           class="w-full pb-16/24 sm:h-full relative rounded-24 overflow-hidden">
        <img class="absolute inset-0 object-center object-cover w-full h-full"
             src="{{ image.i_562x375 }}"
             srcset="{{ image.i_368x245 }} 368w, {{ image.i_562x375 }} 562w"
             sizes="(max-width: 480px), (min-width: 480px)"
             loading="lazy"
             title="{{ image.alt }}"
             alt="{{ image.alt }}">
      </div>
      <div *ngIf="!image"
           class="w-full pb-16/24 sm:h-full relative rounded-24 overflow-hidden">
        <img src="/assets/img/default/sme-default-image-i_562x375.png"
             class="w-full h-full absolute inset-0 object-center object-cover"
             title="{{ slotable.title }}"
             alt="{{ slotable.title }}">
      </div>
    </div>
    <div class="w-20/24 mx-auto sm:w-12/24 sm:px-1/24 text-white">
      <div class="w-full flex flex-col justify-center items-start">
        <h3 class="font-bold text-30 md:text-42 leading-114 mt-8 md:mt-24">{{ slotable.title }}</h3>
        <p class="mt-4 sm:mt-8 text-20 leading-120 mb-14" [innerHTML]="slotable.description"></p>
      </div>
    </div>
  </div>
</div>
