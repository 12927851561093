<div class="w-full py-6 group relative bg-white flex items-center justify-between hover:cursor-pointer border
border-gray-100 rounded-16 shadow-2 hover:border-gray-300 focus:ring-0 focus:outline-none focus:border-gray-300">
  <a *ngIf="slotType !== 'document'" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"
  [ngClass]="{'pointer-events-none hover:cursor-default': slotType === 'custom-html'}"></a>
  <a *ngIf="slotType === 'document'" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <p class="ml-4 sm:ml-6 font-bold text-16 sm:text-18 leading-125 sm:leading-122 text-gray-600">
    {{ slotable.title }}
  </p>
  <span class="text-primary text-24 mx-4 sm:mr-6 leading-100">
    <fa-icon [icon]="['fal','arrow-down-to-bracket']"></fa-icon>
  </span>
</div>
