import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ResourceService } from '../../../Library/resource/resource.service';
import { Detail, DetailAdapter } from '../models/detail.model';
import { ApiError } from '../../../Library/api-data/api-error.model';

@Injectable({
  providedIn: 'root',
})

export class DetailService extends ResourceService<Detail> {

  private detailsSubject: BehaviorSubject<Detail> = new BehaviorSubject<Detail>(null);
  public details$: Observable<Detail> = this.detailsSubject.asObservable();

  constructor(httpClient: HttpClient, adapter: DetailAdapter) {
    super('/details', httpClient, adapter);

    const params = {extended_fields: 'items.menuable'};

    this.get(null, params).pipe(
      take(1),
      tap(data => {
        this.detailsSubject.next(data);
      }),
    ).subscribe();
  }

  get(id: string, data?: {}): Observable<Detail> {
    const params = this.prepareParams(data);

    return this.httpClient.get<Detail>(`${this.url}`, {params}).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
        return this.adapter.adapt(response);
      }),
    );
  }
}
