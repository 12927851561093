export const routes: any = {
  me: {
    articles: 'vodici-i-informacije',
    articleDetail: 'vodici-i-informacije',
    grants: 'pozivi',
    grantDetail: 'poziv',
    contact: 'kontakt',
    enquiry: 'upit',
    page: '',
    profile: 'profil',
    settings: 'podesavanja',
    interests: 'interesovanja',
    auth: 'auth',
    login: 'prijava',
    register: 'registracija',
    forgotPassword: 'zaboravljena-sifra',
    resetPassword: 'promjena-sifre',
    activationCode: 'aktivacioni-kod',
    search: 'pretraga',
    instructions: 'instrukcije',
    instructionDetail: 'instrukcija',
    newsletter: 'bilten'
  },
  en: {
    articles: 'guides-and-information',
    articleDetail: 'guides-and-information',
    grants: 'grants',
    grantDetail: 'grant',
    contact: 'contact',
    profile: 'profile',
    page: '',
    interests: 'interests',
    settings: 'settings',
    auth: 'auth',
    login: 'login',
    register: 'register',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    activationCode: 'activation-code',
    search: 'search',
    instructions: 'instructions',
    instructionDetail: 'instruction',
    newsletter: 'newsletter'
  },
};


export enum RouteName {
  ARTICLE_LIST = 'articles',
  ARTICLE_DETAIL = 'articleDetail',
  GRANT_LIST = 'grants',
  GRANT_DETAIL = 'grantDetail',
  PROFILE = 'profile',
  FORGOT_PASSWORD = 'forgotPassword',
  SETTINGS = 'settings',
  INTERESTS = 'interests',
  AUTH = 'auth',
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
  CUSTOM_PAGE = 'customPage',
  NOT_FOUND = 'notFound',
  SEARCH = 'search',
  INSTRUCTIONS = 'instructions',
  ACTIVATION_CODE = 'activationCode',
  INSTRUCTION_DETAIL = 'instructionDetail',
  NEWSLETTER = 'newsletter',
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
