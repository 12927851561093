<div class="w-full max-w-max mx-auto relative sm:h-[560px] overflow-hidden z-10">
  <div class="static sm:absolute sm:inset-0 flex items-center justify-center z-0"
       [ngClass]="{'hidden sm:block sm:bg-system': !contentModule.image}">
    <picture *ngIf="contentModule.image">
      <source srcset="{{ contentModule.image.i_1920x1280 }}" media="(min-width: 768px)">
      <source srcset="{{ contentModule.image.i_699x466 }}" media="(max-width: 768px)">
      <img srcset="{{ contentModule.image.i_699x466 }}" alt="biznis.gov.me">
    </picture>
  </div>
  <div class="container relative z-10 h-full flex items-center">
    <div class="w-full sm:w-16/24 md:w-11/24 rounded-12 bg-white p-6">
      <div class="flex flex-col gap-4 mb-4">
        <h2 class="text-24 sm:text-30 leading-125 sm:leading-120 font-bold font-raleway text-gray-600">
          {{ slot.title }}
        </h2>
        <p class="text-16 leading-125 font-raleway text-gray"
           [innerHTML]="shortDescription">
        </p>
      </div>
      <a *ngIf="contentModule.slot_type !== 'link'" [appFlLink]="slot.url"
         i18n="@@find_out_more"
        class="inline-block py-4 px-10 bg-primary text-white text-16 sm:text-12 md:text-16 font-bold
          text-center leading-125 rounded-8 hover:bg-category-agriculture hover:text-gray-600 focus:outline-none
          focus:ring-0 focus:border-3 focus:border-gray-200 hover:cursor-pointer">
        Saznaj više
      </a>
      <a *ngIf="contentModule.slot_type === 'link'" href="{{ slot.url }}" target="_blank"
        class="inline-block py-4 px-10 bg-primary text-white text-16 sm:text-12 md:text-16 font-bold
          text-center leading-125 rounded-8 hover:bg-category-agriculture hover:text-gray-600 focus:outline-none
          focus:ring-0 focus:border-3 focus:border-gray-200 hover:cursor-pointer">
        Upitnik
      </a>
    </div>
  </div>
</div>
