import { Injectable } from '@angular/core';
import { Media, MediaAdapter } from 'src/app/Library/media/models/media.model';
import { Resource } from '../../../Library/resource/resource.model';
import { Document, DocumentAdapter } from '../../document/models/document.model';
import { GrantContent } from './grant-content.model';
import { DateTime } from 'luxon';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { GrantType } from '../../grant-type/models/grant-type.model';
import { GrantSubtype, GrantSubtypeAdapter } from '../../grant-subtype/models/grant-subtype.model';
import { GrantJustifiedCost, GrantJustifiedCostAdapter } from '../../grant-justified-cost/models/grant-justified-cost.model';
import { GrantField, GrantFieldAdapter } from '../../grant-field/models/grant-field.model';
import { GrantQuestion, GrantQuestionAdapter } from '../../grant-question/models/grant-question.model';
import { HasSeoItem } from '../../../Library/services/seo/has-seo-item';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';

export class Grant extends Resource<Request> implements HasSeoItem {
  public id?: number;
  public locale: string;
  public title?: string;
  public slug?: string;
  public url?: string;
  public microthumb: string;
  public status?: string;
  public can_apply_desc?: string;
  public availability?: string;
  public institution: string;
  public contact_email: string;


  public how_to_apply_desc: string;
  public how_to_apply_details: string;
  public howToApplyDetailsArray: any;

  public cost_stake_coverage?: number;
  public individual_support_lower_limit?: number;
  public individual_support_upper_limit?: number;
  public total_budget?: number;
  public payment_period?: string;
  public grace_period?: string;

  public content?: GrantContent;

  public grant_type: GrantType;
  public grant_subtypes: GrantSubtype[];

  public cta_label?: string;
  public cta_link?: string;

  public grant_document_id: number;
  public grant_document: Document;
  public documents: Document[];
  public document_forms: Document[];

  public justified_cost_desc?: string;
  public justified_costs: GrantJustifiedCost[];

  public fields: GrantField[];

  public questions: GrantQuestion[];

  public published_at?: string;
  public publishedAtDate?: Date;
  public publishedAtSchemaDate?: string;
  public updated_at: string;
  public updatedAtDate?: Date;
  public updatedAtSchemaDate?: string;
  public start_date: Date;
  public submit_deadline: string;
  public submitDeadlineDate: Date;
  public submitDeadlineMillis: number;

  // SEO
  seo_image_id?: number;
  seo_image?: Media;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_is_auto: boolean;

  seo_fb_image_id?: number;
  seo_fb_image?: Media;
  seo_fb_title?: string;
  seo_fb_description?: string;
  seo_fb_keywords?: string;
  seo_fb_is_auto: boolean;

  seo_tw_image_id?: number;
  seo_tw_image?: Media;
  seo_tw_title?: string;
  seo_tw_description?: string;
  seo_tw_keywords?: string;
  seo_tw_is_auto: boolean;
  // SEO END

  public type: string;
  public label: any;

  public translations: Grant[];
  public is_adapted: boolean = false;
  public toc_items: {
    id: string,
    level: number,
    text: string,
    indent?: number
  }[];

  public constructor(data?: Partial<Grant>) {

    super(data);
  }

  public getTranslation(locale: string): Grant | null {

    if (this.locale === locale) {
      return this;
    }

    let result = null;
    if (this.translations) {
      this.translations.forEach((translation) => {
        if (translation.locale === locale) {
          result = translation;
        }
      });
    }

    return result;
  }

  getUrl(locale?: string): string {
    if (!locale) {
      locale = this.locale;
    }

    switch (locale) {
      case 'en':
        return 'grant/' + this.slug;
      default:
        return 'grant/' + this.slug;
    }
  }

  getSeoItem(): SeoItem {

    const title = this.seo_title ? this.seo_title : this.title + ' | ICT Hub';

    let description = this.seo_description;
    if (!description && this.description) {
      description = this.description.replace(/<\/?[^>]+(>|$)/g, '').substr(0, 160);
    }

    let imageUrl = this.seo_image ? this.seo_image.filename : '';
    if (!imageUrl && this.image) {
      imageUrl = this.image.i_1200x630;
    }

    return new SeoItem({
      title,
      description,
      seo_fb_title: this.seo_fb_title ? this.seo_fb_title : title,
      seo_fb_description: this.seo_fb_description ? this.seo_fb_description : description,
      seo_fb_type: 'website',
      seo_fb_image_url: this.seo_fb_image ? this.seo_fb_image.filename : imageUrl
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class GrantAdapter extends BaseAdapter<Grant> {

  constructor(
    private grantSubtypesAdapter: GrantSubtypeAdapter,
    private mediaAdapter: MediaAdapter,
    private documentAdapter: DocumentAdapter,
    private grantJustifiedCostAdapter: GrantJustifiedCostAdapter,
    private grantFieldAdapter: GrantFieldAdapter,
    private grantQuestionAdapter: GrantQuestionAdapter
  ) {
    super(Grant);
  }

  adapt(data: any): Grant {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    // TODO: add translation of urls
    if (item.locale === 'en') {
      item.url = '/en/grant/' + item.slug;
    } else {
      item.url = '/poziv/' + item.slug;
    }

    if (item.grant_subtypes) {
      item.grant_subtypes = this.grantSubtypesAdapter.adaptArray(item.grant_subtypes);
    }

    if (item.grant_document) {
      item.grant_document = this.documentAdapter.adapt(item.grant_document);
    }

    if (item.documents) {
      item.documents = this.documentAdapter.adaptArray(item.documents);
    }

    if (item.document_forms) {
      item.document_forms = this.documentAdapter.adaptArray(item.document_forms);
    }

    if (item.seo_image) {
      item.seo_image = this.mediaAdapter.adapt(item.seo_image);
    }

    if (item.justified_costs) {
      item.justified_costs = this.grantJustifiedCostAdapter.adaptArray(item.justified_costs);
    }

    if (item.fields) {
      item.fields = this.grantFieldAdapter.adaptArray(item.fields);
    }

    if (item.questions) {
      item.questions = this.grantQuestionAdapter.adaptArray(item.questions);
    }

    if (item.published_at) {
      item.publishedAtDate = DateTime.fromSQL(item.published_at).toJSDate();
      item.publishedAtSchemaDate = DateTime.fromSQL(item.published_at).toFormat('y-LL-dd');
    }

    if (item.updated_at) {
      item.updatedAtDate = DateTime.fromSQL(item.updated_at).toJSDate();
      item.updatedAtSchemaDate = DateTime.fromSQL(item.updated_at).toFormat('y-LL-dd');
    }

    if (item.submit_deadline) {
      item.submitDeadlineDate = DateTime.fromSQL(item.submit_deadline).toJSDate();
      item.submitDeadlineMillis = item.submitDeadlineDate.getTime();
    }

    if (item.how_to_apply_details) {
      item.howToApplyDetailsArray = JSON.parse(item.how_to_apply_details);
    }

    if (item.translations) {
      item.translations = this.adaptArray(item.translations);
    }

    item.is_adapted = true;


    return item;
  }
}
