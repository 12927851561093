import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../../Library/resource/resource.service';
import { GrantSubtype, GrantSubtypeAdapter } from '../models/grant-subtype.model';

@Injectable({
  providedIn: 'root'
})

export class GrantSubtypeService extends ResourceService<GrantSubtype> {

  constructor(httpClient: HttpClient, adapter: GrantSubtypeAdapter) {
    super('/grant-subtypes', httpClient, adapter);
  }

}
