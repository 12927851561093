<div class="w-full bg-transparent absolute top-0 z-100 border-gray-100 text-gray-600 leading-125"
     [ngClass]="{ 'fixed bg-white border-b': isMenuOpen, 'absolute bg-transparent border-b-0' : !isMenuOpen }">
  <div class="container">
    <div class="flex items-center py-9">
      <a [routerLink]="['/']">
        <img src="assets/img/logos/logo-sme-mobile.png"
             class="w-half h-half"
             width="198" height="82"
             alt="Portal za podršku malim i srednjim preduzećima"
             title="Logo - Portal za podršku malim i srednjim preduzećima">
      </a>
      <div class="ml-auto flex items-center">
        <div *ngIf="locale == 'me'" class="mr-6">
          <a [routerLink]="[ locale === 'me' ? searchRoute : 'articleDetail']"
             [queryParams]="{availability: 'open'}"
             (click)="onRedirectClick()">
            <fa-icon class="text-24" [icon]="['fal', 'magnifying-glass']"></fa-icon>
          </a>
        </div>
        <button (click)="onMenuButtonClick()" class="w-8 h-8">
          <fa-icon *ngIf="!isMenuOpen" class="text-24" [icon]="['fal', 'bars']"></fa-icon>
          <fa-icon *ngIf="isMenuOpen" class="text-24" [icon]="['fal', 'xmark']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isMenuOpen"
       class="menu flex flex-col animation">


    <div class="flex-1 overflow-y-auto">
      <ul class="text-18 font-bold">
        <li *ngFor="let menuItem of headerMenu, let i=index" class="border-b border-gray-100">
          <button class="w-full" (click)="onMenuItemClick(i)">
            <a
              [routerLink]="menuItem.type !== 'placeholder' && menuItem.type !== 'custom-url' ? menuItem.link : null"
              [queryParams]="menuItem.query_param"
              routerLinkActive="text-primary">
              <div class="container py-8 flex items-center">
                <span class="font-bold">{{ menuItem.title }}</span>
                <fa-icon *ngIf="menuItem.items[0] && showChildren[i]" class="ml-auto text-primary text-24"
                         [icon]="['fal', 'chevron-up']"></fa-icon>
                <fa-icon *ngIf="menuItem.items[0] && !showChildren[i]" class="ml-auto text-primary text-24"
                         [icon]="['fal', 'chevron-down']"></fa-icon>
              </div>
            </a>
          </button>
          <ul *ngIf="menuItem.items[0] && showChildren[i]">
            <li *ngFor="let childItem of menuItem.items, let first=first"
                class="border-t border-white bg-system"
                [ngClass]="{'border-system' : first, 'border-white': !first}">
              <button class="w-full"
                      (click)="onChildItemClick(childItem)">
                <a [routerLink]="childItem.type !== 'placeholder' && childItem.type !== 'custom-url' ? childItem.link : null"
                   [queryParams]="childItem.query_param"
                   routerLinkActive="text-primary">
                  <div class="container py-8 flex items-center">
                    <span class="font-bold text-primary">{{ childItem.title }}</span>
                  </div>
                </a>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div *ngIf="!user && !localStorageHasToken && locale !== 'en'" class="container py-8">
      <a [appFlRouterLink]="['auth', 'login']"
         (click)="onRedirectClick()"
         class="block w-full ui-btn-primary text-bold text-18">
        <span i18n="@@header_sign_up">Prijavi se</span>
      </a>
    </div>
    <a *ngIf="user"
       [appFlRouterLink]="['profile']"
       class="border-t border-gray-100"
       (click)="onRedirectClick()">
      <div class="container w-full py-8 flex items-center">
        <div class="w-12 h-12 bg-mustard rounded-full flex items-center">
            <span *ngIf="true" class="text-white font-bold text-24 mx-auto">
              {{ user?.full_name | slice: 0:1 }}
            </span>
        </div>
        <p class="text-gray-600 text-18 font-bold ml-4">{{ user?.full_name }}</p>
        <fa-icon class="ml-auto text-primary text-24" [icon]="['fal', 'chevron-right']"></fa-icon>
      </div>
    </a>

    <div class="w-full border-t border-gray-100 py-4 bg-system">
      <div class="container flex justify-center items-center">
        <div class="uppercase text-18 font-bold text-gray-600">
          <a *ngIf="!routeTranslations.en"
             (click)="chooseLanguage('en')"
             class="font-bold hover:cursor-pointer">
            <span [ngClass]="{'text-gray-200': locale == 'me'}">ENG</span>
          </a>
          <a *ngIf="routeTranslations.en"
             [href]="routeTranslations.en"
             class="font-bold hover:cursor-pointer">
            <span [ngClass]="{'text-gray-200': locale == 'me'}">ENG</span>
          </a>
          <span class="ml-1">|</span>
          <a *ngIf="!routeTranslations.me"
             (click)="chooseLanguage('me')"
             class="font-bold hover:cursor-pointer">
            <span class="ml-1" [ngClass]="{'text-gray-200': locale == 'en'}">MNE</span>
          </a>
          <a *ngIf="routeTranslations.me"
             [href]="routeTranslations.me"
             class="font-bold hover:cursor-pointer">
            <span class="ml-1" [ngClass]="{'text-gray-200': locale == 'en'}">MNE</span>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
