<div class="w-full h-full group relative hover:cursor-pointer">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''" class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div *ngIf="slotable.image" class="w-full rounded-16 min-h-56 border
      border-transparent group-hover:border-primary overflow-hidden">
    <img src="{{ slotable.image.i_747x498 }}"
         appImgFallback="/assets/img/default/sme-default-image-i_747x498.png"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
         loading="lazy"
         class="object-center rounded-16 object-cover w-full h-full"
    >
  </div>
  <div *ngIf="!slotable.image" class="w-full rounded-16 min-h-56 border
      border-transparent group-hover:border-primary overflow-hidden">
    <img src="/assets/img/default/sme-default-image-i_747x498.png"
         alt="{{ slotable.title }}"
         title="{{ slotable.title }}"
         class="object-center rounded-16 object-cover w-full h-full"
    >
  </div>
</div>
