<div class="w-full group relative hover:cursor-pointer">
  <a *ngIf="!document" [appFlLink]="slotable.url ? slotable.url : ''"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <a *ngIf="document" href="{{ slotable.filename }}" target="_blank"
     class="absolute inset-0 z-10 focus:outline-none" title="{{ slotable.title }}"></a>
  <div *ngIf="slotable.image"
       class="w-full h-full rounded-16 sm:rounded-24 border border-transparent group-hover:border-primary">
    <img
      class="w-full sm:h-[350px] md:h-[465px] h-full rounded-16 sm:rounded-24 object-cover object-center fade-in-image"
      srcset="{{ slotable.image?.i_368x245 }} 368w, {{ slotable.image?.i_699x466 }} 699w"
      sizes="(max-width: 768px) 368px, (max-width: 1024px) 699px"
      src="{{ slotable.image?.i_699x466 }}"
      appImgFallback="/assets/img/default/sme-default-image-i_699x466.png"
      alt="{{ slotable.title }}"
      title="{{ slotable.title }}"
      width="699"
      height="466"
      loading="lazy"
    >
  </div>
  <div *ngIf="!slotable.image"
       class="w-full h-full rounded-16 sm:rounded-24 border border-transparent hover:border-primary">
    <img
      src="/assets/img/default/sme-default-image-i_699x466.png"
      alt="{{ slotable.title }}"
      title="{{ slotable.title }}"
      width="699"
      height="466"
      loading="lazy"
      class="w-full sm:h-[350px] md:h-[465px] h-full rounded-16 sm:rounded-24 object-cover object-center fade-in-image">
  </div>
  <div class="md:mt-2 flex justify-start items-center min-h-[100px] flex-row sm:justify-end w-full">
    <span class="order-last sm:order-first">
      <span class="mt-2 sm:text-18 md:text-20 font-bold text-black pt-2">
        {{ slotable.author?.full_name ? slotable.author?.full_name : slotable?.title }}
      </span>
      <p class="italic sm:text-16 md:text-18 leading-122">
        {{ !!slotable.seo_fb_description ? slotable.seo_fb_description : slotable?.description }}</p>
    </span>
    <div class="order-first sm:order-first mr-4 sm:mr-0 sm:ml-6 flex justify-center items-center overflow-visible">
      <span class="w-12 h-12 outline outline-1 outline-offset-[-1px] outline-gray-100 rounded-full mt-4 mr-2 relative
      group-hover:bg-category-agriculture focus:outline-primary overflow-visible after:absolute after:inset-0
       slot-a10-loader">
        <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <circle fill="none" stroke="#4540df" stroke-width="5" cx="98" cy="98" r="46" stroke-dasharray="360"
                  stroke-linecap="round" transform="rotate(-90 50 98)">
            <animate attributeName="stroke-dashoffset" values="360;0" dur="4s" repeatCount="indefinite"/>
          </circle>
        </svg>
        <span class="text-primary w-full h-full flex justify-center items-center rounded-full absolute inset-0">
          <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
        </span>
      </span>
    </div>
  </div>
</div>
