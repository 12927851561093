import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-module-a043',
  templateUrl: './content-module-a043.component.html',
  styleUrls: ['./content-module-a043.component.scss']
})
export class ContentModuleA043Component implements OnInit {

  @Input() contentModule: ContentModule;
  isTablet: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 1140 && window.innerWidth > 768 : true;
  isDesktop: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth > 1140 : true;
  numberOSlotsWithoutBorder: number = 1;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktop = window.innerWidth > 1140;
      this.isTablet = window.innerWidth < 1140 && window.innerWidth > 768;
      this.setNumberOfSlotsWithoutBorder();
    }
  }

  constructor(@Inject(PLATFORM_ID) public platformId) {
  }

  ngOnInit(): void {
    this.setNumberOfSlotsWithoutBorder();
  }

  setNumberOfSlotsWithoutBorder(): void {
    if (this.isDesktop) {
      const temp = this.contentModule.slotables.length % 3;
      this.numberOSlotsWithoutBorder = temp !== 0 ? temp : 3;
    } else if (this.isTablet) {
      const temp = this.contentModule.slotables.length % 2;
      this.numberOSlotsWithoutBorder = temp !== 0 ? temp : 2;
    } else {
      this.numberOSlotsWithoutBorder = 1;
    }
  }

}
