import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { User, UserAdapter } from '../models/User.model';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { Router } from '@angular/router';
import { ApiError } from '../../../Library/api-data/api-error.model';

import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string = environment.apiUrl + '/auth';
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user$: Observable<User> = this.user.asObservable();


  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private userAdapter: UserAdapter,
    @Inject(PLATFORM_ID) private platformId
  ) {
  }


  register(data?: {}): Observable<User> {
    return this.httpClient.post(this.url + '/register', data);
  }

  sendActivationCode(activationCode: string, data?: {}): Observable<User> {
    return this.httpClient.post(this.url + '/activate/' + activationCode + '?extended_fields=fields,grant_subtypes', data).pipe(
      tap((response: any) => {
        const user = this.userAdapter.adapt(response.data);
        if (isPlatformBrowser(this.platformId) && response.access_token) {
          localStorage.setItem('accessToken', response.access_token);
        }
        this.user.next(user);
        return user;
      })
    );
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.httpClient.post(this.url + '/request-password-reset', {
      email
    });
  }

  login(data?: {}, params?: {}): Observable<any> {
    return this.httpClient.post(this.url + '/login', data, params).pipe(
      tap((response: any) => {
          const userData = {
            accessToken: response.access_token,
            ...response.data
          };
          const user = this.userAdapter.adapt(userData);
          if (isPlatformBrowser(this.platformId) && response.access_token) {
            localStorage.setItem('accessToken', response.access_token);
          }
          this.user.next(user);
          return user;
        }
      )
    );
  }

  checkUser(accessToken): Observable<User> {
    const extended_fields = new HttpParams().set('extended_fields', 'fields,grant_subtypes');
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`
      }),
      params: extended_fields
    };

    return this.httpClient.post(this.url + '/check', {}, options).pipe(
      catchError(response => {
        if (response.status === 400 || response.status === 401) {
          this.logout(true);
          return;
        }
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      tap((response: any) => {
          const userData = {
            accessToken: response.access_token,
            ...response.data
          };
          const user = this.userAdapter.adapt(userData);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('accessToken', response.access_token);
          }
          this.user.next(user);
          return user;
        }
      )
    );
  }

  autoLogin(): void {
    let accessToken = '';
    if (isPlatformBrowser(this.platformId)) {
      accessToken = localStorage.getItem('accessToken');
    }

    if (!accessToken) {
      return;
    }

    this.checkUser(accessToken).subscribe();
  }

  logout(shouldNotNavigate?: boolean): void {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken');
    }
    this.user.next(null);
    if (shouldNotNavigate) {
      return;
    }
    this.router.navigate([`${routesTrans[currentLocale].auth}/${routesTrans[currentLocale].login}`]);
  }

  updatePassword(data?: any): Observable<User> {
    let accessToken = '';
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('accessToken')) {
      accessToken = localStorage.getItem('accessToken');
    }
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.httpClient.post(this.url + '/update-password', data, options).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
          if (response.data) {
            return this.userAdapter.adapt(response.data);
          }
        }
      )
    );
  }

  updateUser(id: number, data?: any): Observable<User> {
    let accessToken = '';
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('accessToken')) {
      accessToken = localStorage.getItem('accessToken');
    } else {
      return;
    }
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.httpClient.put(environment.apiUrl + `/users/${id}`, data, options).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
          if (response.data) {
            const userData = {
              accessToken,
              ...response.data
            };
            const user = this.userAdapter.adapt(userData);
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('accessToken', accessToken);
            }
            this.user.next(user);
            return user;
          }
        }
      )
    );
  }

  resetPassword(code: string, data?: any): Observable<User> {
    return this.httpClient.post(this.url + '/reset-password/' + code, data).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }), map((response: any) => {
        if (response.data) {
          return this.userAdapter.adapt(response.data);
        }
      })
    );
  }

  prepareParams(data?: {}): HttpParams {
    let params = new HttpParams();

    if (!data) {
      return params;
    }

    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        params = params.set(property, data[property]);
      }
    }

    return params;
  }

}
