import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class GrantQuestion extends Resource<Request> {
  public id: number;
  public locale: string;
  public title: string;
  public description: string;
  public slug: string;

  public constructor(data?: {}) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})
export class GrantQuestionAdapter extends BaseAdapter<GrantQuestion> {

  constructor(
    private injector: Injector,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
    super(GrantQuestion);
  }

  adapt(data: any): GrantQuestion {
    return super.adapt(data);
  }

}
