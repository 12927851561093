import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject, OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
