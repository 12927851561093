<a [routerLink]="slotable.url" class="block w-full bg-white hover:bg-category-agriculture text-gray-600 hover:text-white rounded-12 p-4">
  <div class="w-16 h-16 rounded-16 bg-[#4540DF25] flex items-center justify-center mb-6">
    <span class="text-36 font-bold text-category-sme">
      {{ index }}
    </span>
  </div>
  <p class="text-18 leading-122 font-bold font-raleway">
    {{ slotable.title }}
  </p>
</a>
