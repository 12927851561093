<section class="container" style="color:var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden" class="w-full flex items-center justify-start">
    <h2 class="font-bold text-24 leading-125 text-gray-600 sm:text-36 sm:leading-115">{{ contentModule.name }}</h2>
  </header>
  <div class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-6 first:sm:mt-8">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a70
        [document]="contentModule.slot_type === 'document'"
        [slotable]="slotable"></app-slot-a70>
    </div>
  </div>
</section>

