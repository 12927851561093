<section class="relative w-full max-h-full" style="color: var(--page-accent);">
  <div class="absolute top-0 left-0 bottom-7 sm:bottom-10 bg-system module-a30-backdrop z-0 rounded-r-36"></div>
  <div class="container mt-8 relative mt-6 z-20">
    <header *ngIf="!contentModule.name_hidden" class="w-full flex content-start pt-6 sm:pt-8 z-20">
      <h2 class="text-24 sm:text-36 font-bold text-gray-600 leading-125 sm:leading-115">
        {{ contentModule.name }}
      </h2>
    </header>
    <div class="w-full sm:w-18/24 md:w-16/24 sm:grid grid-cols-1 gap-4 sm:grid-cols-2 mt-2 sm:mt-4">
      <div *ngFor="let slotable of contentModule.slotables"
           class="h-13 sm:h-18 mt-4 w-full "
           tabindex="0">
        <app-slot-a30
          [document]="contentModule.slot_type === 'document'"
          [slotable]="slotable">
        </app-slot-a30>
      </div>
    </div>
  </div>
</section>
