import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { ContentModuleBaseComponent } from '../content-module-base.component';

@Component({
  selector: 'app-content-module-a060',
  templateUrl: './content-module-a060.component.html',
})
export class ContentModuleA060Component implements ContentModuleBaseComponent, OnInit {

  @Input() contentModule: ContentModule;

  constructor() {
  }

  ngOnInit(): void {
  }

}
