<section class="w-full bg-primary pt-10 sm:pt-14 pb-12 sm:pb-16">
  <div class="container">
    <h2 class="text-white text-24 sm:text-36 font-bold leading-115 mb-6">
      {{ contentModule.name }}
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div *ngFor="let slotable of contentModule.slotables, let index = index">
        <app-slot-a045 [slotable]="slotable" [index]="index + 1"></app-slot-a045>
      </div>
    </div>
  </div>
</section>
