import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-a50',
  templateUrl: './slot-a50.component.html',
  styleUrls: ['./slot-a50.component.scss']
})
export class SlotA50Component implements OnInit {

  @Input() slotable: any;
  @Input() isEven: boolean;
  @Input() document: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
