import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-module-a011',
  templateUrl: './content-module-a011.component.html',
  styleUrls: ['./content-module-a011.component.scss']
})
export class ContentModuleA011Component implements OnInit {

  @Input() contentModule: ContentModule;
  activeSlotable: any;
  activeSlotableIndex: number = 0;
  @ViewChild('searchElement') searchElement: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) public platformId,
  ) {
  }

  ngOnInit(): void {

    this.activeSlotable = this.contentModule.slotables[this.activeSlotableIndex];
    this.activeSlotableIndex = (this.activeSlotableIndex + 1) % this.contentModule.slotables.length;
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.activeSlotable = this.contentModule.slotables[this.activeSlotableIndex];
        this.activeSlotableIndex = (this.activeSlotableIndex + 1) % this.contentModule.slotables.length;
      }, 4000);
    }
  }
}
