<section class="relative w-full max-h-full">
  <div class="container mt-8 relative mt-6 z-20">
    <header *ngIf="!contentModule.name_hidden" class="w-18/24 sm:w-12/24 sm:ml-5/24 pt-6 sm:pt-8 z-20">
      <h2 class="text-24 sm:text-36 font-bold text-gray-600 leading-125 sm:leading-115">
        {{ contentModule.name }}
      </h2>
    </header>
    <div class="w-full sm:w-14/24 sm:ml-5/24 grid grid-cols-1 gap-3 sm:gap-4 mt-8 sm:mt-6">
      <div *ngFor="let slotable of contentModule.slotables"
           class="w-full"
           tabindex="0">
        <app-slot-a80
          [slotable]="slotable"
          [slotType]="contentModule.slot_type">
        </app-slot-a80>
      </div>
    </div>
  </div>
</section>
